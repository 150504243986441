import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./CartPage.scss";

import { db } from "../../firebase/dbAccess";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import PaymentMethod from "../../components/common/PaymentMethod/PaymentMethod";
import FloatingWhatsAppIcon from "../../components/utils/FloatingWhatsAppIcon/FloatingWhatsAppIcon";
import getFrete from "../../services/apiFrete";
import Loader from "../../components/utils/Loader/Loader";
import {
  decreaseQuantity,
  getTotalCartPice,
  increaseQuantity,
  loadCart,
  removeFromCart,
} from "../../Store/CartSlice";

const removeNonNumericCharacters = (inputString) => {
  // Utiliza una expresión regular para mantener solo dígitos
  return inputString.replace(/\D/g, "");
};

const CartPage = () => {
  const dataCustomerObject = useSelector(
    (state) => state.customerData.dataCustomerObject
  );
  // console.log("dataCustomerObject", dataCustomerObject);
  const userId = useSelector((state) => state.user.userId);
  const shoppingCart = useSelector((state) => state.cart.shoppingCart);
  const totalPrice = useSelector(getTotalCartPice);
  const [selectedFrete, setSelectedFrete] = useState("PAC"); // Estado para almacenar el servicio de frete seleccionado por el usuario
  const [freteValue, setFreteValue] = useState(0); // Estado para almacenar el valor del flete seleccionado
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [frete, setFrete] = useState({
    fretePac: 0,
    prazoEntregaPACvalor: 0,
    freteSedex: 0,
    prazoEntregaSedexvalor: 0,
  });

  useEffect(() => {
    const dataFetch = async () => {
      const postalCode = removeNonNumericCharacters(
        dataCustomerObject.postalCode
      );
      if (postalCode) {
        const getFreteData = await getFrete(postalCode, 100);
        setFrete((prev) => ({
          ...prev, // Mantener los valores anteriores del estado
          fretePac: getFreteData.valorPAC,
          prazoEntregaPACvalor: getFreteData.prazoEntregaPAC,
          freteSedex: getFreteData.valorSedex,
          prazoEntregaSedexvalor: getFreteData.prazoEntregaSedex,
        }));
      }
    };
    dataFetch();
  }, [dataCustomerObject]);

  useEffect(() => {
    if (selectedFrete === "PAC") {
      setFreteValue(frete.fretePac);
    } else if (selectedFrete === "SEDEX") {
      setFreteValue(frete.freteSedex);
    }
  }, [selectedFrete, frete]);

  const handleIncreaseQty = (itemId) => {
    dispatch(increaseQuantity({ id: itemId }));
  };
  const handleDecreaseQty = (itemId) => {
    dispatch(decreaseQuantity({ id: itemId }));
  };

  const handleRemoveItem = (itemId) => {
    dispatch(removeFromCart({ id: itemId }));
  };

  const handleContinueClick = async () => {
    const timestamp = new Date();

    await addDoc(collection(db, "Ventas"), {
      id_usuario: userId,
      dataCustomerObject,
      shoppingCart,
      totalPriceWithoutFrete: totalPrice,
      totalPriceWithFrete: totalPrice + freteValue,
      estado: "Verificando dados",
      fecha: timestamp,
      freteValue,
      selectedFrete,
      prazoEntregaPACvalor: frete.prazoEntregaPACvalor,
      prazoEntregaSedexvalor: frete.prazoEntregaSedexvalor,
    });
    // no tiene sentido utilizar load_cart para resetear valores

    dispatch(loadCart({ shoppingCart: [] }));

    localStorage.removeItem("cart");
    navigate("/confirmation-page");
    // Aquí puedes realizar las acciones necesarias con los datos del formulario
  };

  const formattedTotalPrice = (
    Math.round((totalPrice + freteValue) * 100) / 100
  ).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  if (frete.fretePac === 0) {
    return (
      <div className="loder-container min-h-screen max-w-screen-lg m-auto header-content">
        <Loader />
      </div>
    );
  }
  if (shoppingCart.length === 0) {
    return (
      <div className="min-h-screen max-w-screen-lg m-auto header-content">
        <div className="container-empty-cart mt-8 text-center">
          <p>Nenhum produto no carrinho.</p>
        </div>
      </div>
    );
  }
  return (
    <main className="cart-page block">
      <div>
        <>
          <h2 className="text-4xl py-16">Resumo da compra</h2>
          <ul className="cart-list">
            {shoppingCart.map((item) => (
              <li key={item.id} className="cart-item">
                <div className="item-info">
                  <h3>{item.product.title}</h3>
                  <p>
                    Preço:{" "}
                    {parseFloat(item.product.price).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </p>
                </div>
                <div className="item-quantity">
                  <button
                    className="quantity-btn"
                    onClick={() => handleDecreaseQty(item.id)}
                  >
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    className="quantity-btn"
                    onClick={() => handleIncreaseQty(item.id)}
                  >
                    +
                  </button>
                </div>
                <button
                  className="remove-btn"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  Remover
                </button>
              </li>
            ))}
          </ul>
          <div className="frete-options">
            {frete.fretePac > 0 && (
              <div className="radio-option">
                <input
                  type="radio"
                  id="fretePac"
                  name="frete"
                  value="PAC"
                  checked={selectedFrete === "PAC"}
                  onChange={() => {
                    setSelectedFrete("PAC");
                    setFreteValue(frete.fretePac);
                  }}
                />
                <label htmlFor="fretePac">
                  PAC:{" "}
                  {frete.fretePac.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}{" "}
                  (tempo estimado de entrega é de até{" "}
                  {parseInt(frete.prazoEntregaPACvalor)} dias)
                </label>
              </div>
            )}

            {frete.freteSedex > 0 && (
              <div className="radio-option">
                <input
                  type="radio"
                  id="freteSedex"
                  name="frete"
                  value="SEDEX"
                  checked={selectedFrete === "SEDEX"}
                  onChange={() => {
                    setSelectedFrete("SEDEX");
                    setFreteValue(frete.freteSedex);
                  }}
                />
                <label htmlFor="freteSedex">
                  Sedex:{" "}
                  {frete.freteSedex.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}{" "}
                  (tempo estimado de entrega é de até{" "}
                  {parseInt(frete.prazoEntregaSedexvalor)} dias)
                </label>
              </div>
            )}
          </div>
          {frete.fretePac > 0 && (
            <div>
              <div className="cart-summary">
                <p>Total: {formattedTotalPrice}</p>
              </div>
              <PaymentMethod />
            </div>
          )}
          <div className="btn-envio mt-8">
            {frete.fretePac > 0 && (
              <button
                onClick={() => handleContinueClick()}
                className="checkout-btn bg-slate-900 hover:bg-neutral-400"
              >
                Comprar
              </button>
            )}

            <p className="payment-shipping-details mt-6 text-gray-500">
              Caso o seu pedido seja por atacado, entre em contato com a nossa
              equipe de vendas através do e-mail adm@tuaregcomex.com ou pelo
              WhatsApp 45 999628659, mencionando que é um "Pedido por atacado".
            </p>
          </div>
        </>
      </div>

      <FloatingWhatsAppIcon />
    </main>
  );
};

export default CartPage;
