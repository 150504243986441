import "./PaymentMethod.scss";
import { FaCreditCard, FaQuestionCircle } from "react-icons/fa";

const PaymentMethod = () => {
  return (
    <div className="payment-method mt-8">
      <h2 className="text-2xl mb-4">Modalidade de Pagamento</h2>

      <div className="payment-option">
        <div className="payment-icon">
          <FaCreditCard />
        </div>
        <div className="payment-details">
          <h3>Pagamento via Pix</h3>
          <p>
            Após efetuar o pagamento, lembre-se de clicar no botão 'Comprar'.
            Assim, receberemos as informações relacionadas ao seu pedido.
          </p>
          <p>Chave Pix: 43887344000177</p>
          <p>
            Gentileza enviar o comprovante para o e-mail adm@tuaregcomex.com ou
            pelo WhatsApp 45 999628659.
          </p>
        </div>
      </div>

      <div className="payment-option">
        <div className="payment-icon">
          <FaQuestionCircle />
        </div>
        <div className="payment-details">
          <h3>Outros métodos de pagamento</h3>
          <p>
            Consulte-nos para obter informações sobre outras formas de pagamento
            disponíveis.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
