import React from "react";
import { Helmet } from "react-helmet";
import FloatingWhatsAppIcon from "../../components/utils/FloatingWhatsAppIcon/FloatingWhatsAppIcon";

const SobreNos = () => {
  return (
    <>
      <Helmet>
        <title>
          Tuareg - Bem-vindo à nossa Loja de Rolamentos de Qualidade
        </title>
        <meta
          name="description"
          content="Oferecemos uma ampla variedade de rolamentos de alta qualidade. Nossos produtos são confiáveis e ideais para aplicações industriais e automotivas"
        />
        <meta name="keywords" content="tuareg, rolamentos, qualidade" />
        <meta name="author" content="Tuareg Comercio Exterior" />
      </Helmet>
      <div className="flex items-center justify-center mt-24 mb-24">
        <div className="max-w-2xl  mx-auto px-4">
          <h1 className=" text-3xl font-semibold mb-8 text-center">
            Sobre Nós
          </h1>
          <p className="leading-relaxed text-base mb-10">
            Bem-vindo à nossa página de produtos de rolamentos! Somos uma
            empresa especializada em fornecer rolamentos de alta qualidade para
            diversos setores industriais e mecânicos.
          </p>
          <h2 className="text-2xl font-semibold mb-4">Missão</h2>
          <p className="leading-relaxed text-base mb-10">
            Nossa missão é fornecer rolamentos confiáveis e duráveis que atendam
            às demandas dos nossos clientes. Trabalhamos em estreita colaboração
            com fabricantes renomados para garantir a qualidade de nossos
            produtos e oferecer soluções eficientes.
          </p>
          <h2 className="text-2xl font-semibold mb-4">Qualidade e Confiança</h2>
          <p className="leading-relaxed text-base mb-10">
            Nosso compromisso com a qualidade é fundamental. Todos os nossos
            rolamentos passam por rigorosos testes de qualidade para garantir
            que atendam aos mais altos padrões do mercado. Além disso,
            oferecemos garantia de satisfação aos nossos clientes, pois
            valorizamos a confiança depositada em nossos produtos.
          </p>
          <h2 className="text-2xl font-semibold mb-4">Equipe Especializada</h2>
          <p className="leading-relaxed text-base mb-10">
            Nossa equipe é composta por profissionais experientes e
            especializados no ramo de rolamentos. Estamos sempre prontos para
            oferecer suporte técnico e auxiliar nossos clientes na escolha dos
            rolamentos mais adequados para suas necessidades específicas.
          </p>
          <p className="leading-relaxed text-base mb-10">
            Entre em contato conosco hoje mesmo e descubra como podemos ajudá-lo
            a obter os melhores rolamentos para o seu negócio!
          </p>
          {/* Sección de Informações da Empresa */}
          <div className="mt-12">
            <h2 className="text-2xl font-semibold mb-4">
              Informações da Empresa
            </h2>
            <p>
              <strong>CNPJ:</strong> 43.887.344/0001-77
            </p>

            <p>
              <strong>Endereço:</strong> São Miguel do Iguaçu, Rua Torres 587,
              Paraná - CEP: 85877-000
            </p>
          </div>
        </div>
      </div>
      <FloatingWhatsAppIcon />
    </>
  );
};

export default SobreNos;
