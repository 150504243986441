import React from "react";
import img01 from "../../../img/gallery/img-gal-01.jpeg";
import img02 from "../../../img/gallery/img-gal-02.jpeg";
import img03 from "../../../img/gallery/img-gal-03.jpeg";
import img04 from "../../../img/gallery/img-gal-04.jpeg";
import img05 from "../../../img/gallery/img-gal-05.jpeg";
import img06 from "../../../img/gallery/img-gal-06.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Gallery = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <div className="flex w-full mb-20 flex-wrap">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4">
            Conheça nossos produtos
          </h1>
          <p className="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base">
            Explore nossa galeria. Aqui você encontrará uma variedade de
            rolamentos de qualidade para atender às suas necessidades. Confere e
            descubra alguns dos exemplos que lhe oferecemos na nossa loja online
          </p>
        </div>
        <div className="flex flex-wrap md:-m-2 -m-1">
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-1/2">
              <LazyLoadImage
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img01}
                // src="https://dummyimage.com/500x300"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <LazyLoadImage
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img02}
                // src="https://dummyimage.com/501x301"
              />
            </div>
            <div className="md:p-2 p-1 w-full">
              <LazyLoadImage
                alt="gallery"
                className="w-full h-full object-cover object-center block"
                src={img03}
                // 600x360
              />
            </div>
          </div>
          <div className="flex flex-wrap w-1/2">
            <div className="md:p-2 p-1 w-full">
              <LazyLoadImage
                alt="gallery"
                className="w-full h-full object-cover object-center block"
                src={img04}
                // 601x361
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <LazyLoadImage
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img05}
                // src="https://dummyimage.com/502x302"
              />
            </div>
            <div className="md:p-2 p-1 w-1/2">
              <LazyLoadImage
                alt="gallery"
                className="w-full object-cover h-full object-center block"
                src={img06}
                // src="https://dummyimage.com/503x303"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
