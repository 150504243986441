export const getErrorMessageInPortuguese = (errorCode) => {
  const errorMessages = {
    "auth/user-not-found": "Usuário não encontrado.",
    "auth/wrong-password": "Senha incorreta.",
    "auth/email-already-in-use": "Este e-mail já está em uso.",
    "auth/invalid-email": "E-mail inválido.",
    "auth/weak-password":
      "Senha fraca. A senha deve ter pelo menos 6 caracteres.",
    "auth/too-many-requests":
      "Login da conta bloqueado devido a muitas tentativas",
    "auth/popup-closed-by-user":
      "O pop-up de login do Google foi fechado pelo usuário",
    // Agrega otros mensajes de error según sea necesario
  };

  const errorMessage = errorMessages[errorCode];

  return errorMessage || "Ocorreu um erro.";
};
