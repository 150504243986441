import { Navigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { auth } from "../../../firebase/dbAccess";

const PrivateRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="fake-container min-h-screen"></div>
        <div className="loading-container spinner-absolute">
          <FaSpinner className="spinner" />
        </div>
      </>
    );
  }

  // Si el usuario está autenticado, renderiza el contenido protegido
  if (user) {
    return <Outlet />;
  }

  // Si el usuario no está autenticado, redirige a la página de productos
  return <Navigate to="/products" />;
};

export default PrivateRoute;
