import React from "react";
import "./AsideBlockSumary.scss";
export function AsideBlockSumary({
  shoppingCart,
  handleDecreaseQty,
  handleIncreaseQty,
  handleRemoveItem,
  formattedTotalPrice,
}) {
  return (
    <aside className="order-summary-block my-16 text-xl">
      <h3 className="order-summary-block__title">Resumo do Pedido</h3>
      <div className="order-summary-block__content">
        <ul>
          {shoppingCart.map((item) => (
            <li key={item.id} className="order-summary-block__li">
              <div className="item-info">
                <h3>{item.product.title}</h3>
                <p>
                  {parseFloat(item.product.price).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
              <div className="cart-item justify-center">
                <div className="item-quantity">
                  <button
                    className="quantity-btn"
                    onClick={() => handleDecreaseQty(item.id)}
                  >
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    className="quantity-btn"
                    onClick={() => handleIncreaseQty(item.id)}
                  >
                    +
                  </button>
                </div>
                <button
                  className="remove-btn"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  Remover
                </button>
              </div>
            </li>
          ))}
        </ul>
        <p className="order-summary-block__total">
          Total: {formattedTotalPrice}
        </p>
      </div>
    </aside>
  );
}

export default AsideBlockSumary;
