import React, { useEffect, useRef, useState } from "react";
import "./LoginPopUp.scss"; // Asegúrate de que el archivo CSS esté en la misma ubicación que este componente
import CustomButton from "../../utils/custom-button/custom-button";
import { Link } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";
import { FaGoogle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  SignUpFirebase,
  loginFirebase,
  loginFirebaseWithGoogle,
  setError,
} from "../../../Store/UserSlice";
function LoginPopUp({ onClose }) {
  const popupRef = useRef(null);
  const [formType, setFormType] = useState("login");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // const userName = useSelector((state) => state.user.userName);
  //recaptcha parameters
  const [errorCaptcha, setErrorCaptcha] = useState(false);
  const [verified, setVerified] = useState(false);
  const erroSignIn = useSelector((state) => state.user.error);
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  //
  const handleLoginClick = () => {
    setFormType("login");
  };

  const handleSignupClick = () => {
    setFormType("signup");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formType === "login") {
      try {
        await dispatch(loginFirebase({ email, password })).unwrap();
        onClose(); // Close popUp
      } catch (err) {
        console.log("error 5050", err);
      }
    } else if (formType === "signup") {
      if (password !== confirmPassword) {
        dispatch(setError("As senhas não correspondem"));
        return;
      }
      if (!verified) {
        setErrorCaptcha(true);
        return;
      }
      // Add the logic for registration with email and password here

      try {
        await dispatch(
          SignUpFirebase({ email, password, displayName })
        ).unwrap();
        onClose(); // Close popUp
      } catch (err) {
        console.log("error 5050 ", err);
      }
    }
    setDisplayName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "displayName":
        setDisplayName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      await dispatch(loginFirebaseWithGoogle()).unwrap();
      onClose(); // Close popUp
    } catch (err) {
      console.log(err);
    }
  };

  // recaptcha function
  const handleOnChange = (value) => {
    // console.log("Captcha value:", value);
    setVerified(true);
    setErrorCaptcha(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);
  return (
    <div className="fullscreen-overlay">
      <div ref={popupRef} className={` wrapper ${formType}`}>
        <div className="title-text">
          <div className={`title ${formType}`}>
            {formType === "login" ? "Login" : "Signup"}
          </div>
        </div>
        <div className="form-container">
          <div className="slide-controls">
            <input
              className="login-popup-radio"
              type="radio"
              name="slide"
              id="login"
              checked={formType === "login"}
              onChange={handleLoginClick}
            />
            <input
              className="login-popup-radio"
              type="radio"
              name="slide"
              id="signup"
              checked={formType === "signup"}
              onChange={handleSignupClick}
            />
            <label htmlFor="login" className={`slide login`}>
              Login
            </label>
            <label htmlFor="signup" className={`slide signup`}>
              Signup
            </label>
            <div className="slider-tab"></div>
          </div>

          {/* Login with social media */}

          <>
            <div className="striped striped-before">
              <span className="striped-line"></span>
              <span className="striped-text">Faça login com</span>
              <span className="striped-line"></span>
            </div>
            <div className="method">
              <div className="method-control">
                <div className="method-action" onClick={handleSignInWithGoogle}>
                  <FaGoogle className="google-icon" />
                  <span>Sign in with Google</span>
                </div>
              </div>
            </div>
            <div className="striped striped-after">
              <span className="striped-line"></span>
              <span className="striped-text">Ou</span>
              <span className="striped-line"></span>
            </div>
          </>

          {/*  */}

          <div className="form-inner">
            <form className={`${formType}`} onSubmit={handleSubmit}>
              {formType === "signup" && (
                <div className="field">
                  <input
                    name="displayName"
                    type="text"
                    value={displayName}
                    placeholder="Nome completo"
                    onChange={handleChange}
                    label="Nome completo"
                    required
                  />
                </div>
              )}
              <div className="field">
                <input
                  name="email"
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={handleChange}
                  label="Email"
                  required
                />
              </div>
              <div className="field">
                <input
                  name="password"
                  type="password"
                  value={password}
                  placeholder="Password"
                  onChange={handleChange}
                  label="Senha"
                  required
                />
              </div>
              {formType === "signup" && (
                <>
                  <div className="field mb-6">
                    <input
                      name="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      label="Confirm Password"
                      required
                    />
                  </div>
                  <ReCAPTCHA
                    hl="pt"
                    ref={recaptchaRef}
                    sitekey="6LejlIQeAAAAAHAENqEosDSjyl9fadTfrd1pbd1i"
                    onChange={handleOnChange}
                    style={{ marginBottom: "10px" }}
                  />
                </>
              )}
              <div className="field btn">
                <div className="btn-layer"></div>
                <CustomButton className="login-submit-btn" type="submit">
                  {formType === "login" ? "Login" : "Signup"}
                </CustomButton>
              </div>
              {formType === "login" && (
                <div className="pass-link">
                  <Link to="/redefinir-senha">Esqueceu sua senha?</Link>
                </div>
              )}
              {formType === "login" ? (
                <div className="signup-link" onClick={handleSignupClick}>
                  Ainda não é membro? <strong>Inscreva-se agora</strong>
                </div>
              ) : (
                <div className="signup-link" onClick={handleLoginClick}>
                  Já é membro? <strong>Faça o login agora</strong>
                </div>
              )}
              {erroSignIn && (
                <p className="text-xl text-red-500 mt-2 text-sm">
                  {erroSignIn}
                </p>
              )}
              {errorCaptcha && (
                <p className="text-xl text-red-500 mt-2 text-sm">
                  Valide o captcha!
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPopUp;
