import { firestore } from "./dbAccess";

const createUserProfileDocument = async (userAuth, displayName) => {
  if (!userAuth) return;
  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();
  if (!snapShot.exists) {
    const { email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
      });
    } catch (err) {
      throw Error(err.code);
    }
  }
  return userRef;
};
export default createUserProfileDocument;
