import React from "react";

import pix from "../../../img/pix-106.png";
// import correios from "../../../img/correios2.png";
import "./Footer.scss";
import { Link } from "react-router-dom";
import SocialMediaIcons from "../../utils/SocialMediaIcon/SocialMediaIcon";
import { LogoSVG } from "../../utils/LogoSVG";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer text-gray-600 body-font flex gap-y-8   flex-col mb-8">
      <div className="footer__row row-1 container m-auto flex justify-between">
        <div className="ele-1 flex max-md:mx-auto">
          <div className=" payment-wrapper max-sm:width-[80dvw] mx-auto flex  items-center justify-center">
            <span className="md:mx-1 md:ml-auto text-gray-500">
              Método de pagamento:
            </span>
            <div className="icon-wrapper flex">
              <div className="image-container  h-[1.8rem] mr-4 ml-2">
                <img className="h-[100%]" src={pix} alt="pix" />
              </div>
              {/* <div className="image-container  h-[1.8rem]">
                <img className="h-[100%]" src={correios} alt="correios" />
              </div> */}
            </div>
          </div>
        </div>
        <SocialMediaIcons size="32" color="#7b818e" />
      </div>
      <div className="footer__row row-2 container m-auto flex justify-between">
        {/* Elementos a la izquierda */}
        <div className="ele-3 flex title-font font-medium items-center text-gray-900  max-md:mx-auto">
          <div className="logo-container w-32 h-12 cursor-pointer ">
            <LogoSVG />
          </div>
          <p className="text-sm text-gray-500 ml-4 pl-4 border-l-2 border-gray-200 flex items-center ">
            © {currentYear} Tuareg
          </p>
        </div>
        {/* Elementos a la derecha */}
        <div className="ele-4 ul-container flex   max-md:mx-auto">
          <div className="li-item text-gray-500 py-2 mt-0">
            <Link className="hover:underline" to="/politica-privacidade">
              Política de privacidade
            </Link>
          </div>
          <div className=" li-item text-gray-500 ml-4 pl-4 border-l-2 border-gray-200 py-2 mt-0">
            <Link className="hover:underline" to="/termos-condicoes">
              Termos e condições
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
