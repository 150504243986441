import React, { useState } from "react";
import ProductsCard from "../../components/common/ProductsCard";

import { AiOutlineSearch } from "react-icons/ai";
import { Helmet } from "react-helmet";
import FloatingWhatsAppIcon from "../../components/utils/FloatingWhatsAppIcon/FloatingWhatsAppIcon";
import { getProducts } from "../../services/apiProducts";
import { useLoaderData } from "react-router-dom";
import("./products.scss");
const Products = () => {
  const products = useLoaderData();

  // const [products, setProducts] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div>
      <Helmet>
        <title>
          Tuareg - Bem-vindo à nossa Loja de Rolamentos de Qualidade
        </title>
        <meta
          name="description"
          content="Oferecemos uma ampla variedade de rolamentos de alta qualidade. Nossos produtos são confiáveis e ideais para aplicações industriais e automotivas"
        />
        <meta
          name="keywords"
          content="tuareg, rolamentos, esferas de aço, loja online"
        />
        <meta name="author" content="Tuareg Comercio Exterior" />
      </Helmet>

      <>
        <div className="search-field-for-products">
          <input
            type="text"
            placeholder="Pesquisar produto"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button>
            <AiOutlineSearch />
          </button>
        </div>
        <ProductsCard products={filteredProducts} />
      </>

      <FloatingWhatsAppIcon />
    </div>
  );
};
export async function loader() {
  const products = await getProducts();
  return products;
}
export default Products;
