import productsData from "../data/products.json";
// After get data from API

// getProducts ***********************************************
export async function getProducts() {
  return productsData;
}
// export async function getProducts() {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(productsData);
//     }, 5000); // 5000 milisegundos son 5 segundos
//   });
// }
// getProducts ***********************************************

// getProduct ***********************************************
// Función para limitar caracteres sin romper palabras
const limitCharacters = (text, limit) => {
  text = text.replace(/<br\s*\/?>/g, "");
  if (text.length <= limit) {
    return text;
  }

  const trimmedText = text.slice(0, limit);
  const lastSpaceIndex = trimmedText.lastIndexOf(" ");

  if (lastSpaceIndex !== -1) {
    return `${trimmedText.slice(0, lastSpaceIndex)}`;
  }

  return `${trimmedText}`;
};
export async function getProduct(id) {
  const product = productsData.find((item) => item.titleUrl === id);
  if (!product) throw Error(`Erro: Não foi possível localizar o produto`);
  const limitedTitle = limitCharacters(product.title, 60);
  const limitedDescription = limitCharacters(product.description, 151);
  return { product, limitedTitle, limitedDescription };
}
// getProduct ***********************************************

// export async function updateProduct(id) {}

// export async function createProduct(newProduct) {}
