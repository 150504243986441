import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";

const Error = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">Oops!</h1>
      <p className="text-lg text-gray-600 mb-8">
        {error.data || error.message}
      </p>
      <p className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 mb-12">
        Algo deu errado
      </p>
      <button
        onClick={() => navigate(-1)}
        className="px-6 py-3 bg-slate-900 text-white rounded-lg hover:bg-neutral-400 transition-colors duration-300"
      >
        Voltar
      </button>
    </div>
  );
};

export default Error;
