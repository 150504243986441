import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebase/dbAccess";
import createUserProfileDocument from "../firebase/createUserProfile";
import { getErrorMessageInPortuguese } from "../components/utils/loginErrorMessage/ErrorMessage";

//SingIn with google dependency
import firebase from "firebase/compat/app";

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
/////////
export const SignUpFirebase = createAsyncThunk(
  "user/SignUpFirebase",
  async function ({ email, password, displayName }) {
    const auth = getAuth();
    // Create the user in authentication firebase
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await createUserProfileDocument(user, displayName);

    return { id: user.uid, displayName };
  }
);

export const loginFirebase = createAsyncThunk(
  "user/loginFirebase",
  async function ({ email, password }) {
    const auth = getAuth();
    const { user } = await signInWithEmailAndPassword(auth, email, password);

    return { id: user.uid };
  }
);

export const loginFirebaseWithGoogle = createAsyncThunk(
  "user/loginFirebaseWithGoogle",
  async function () {
    const { user } = await auth.signInWithPopup(provider);
    await createUserProfileDocument(user, user.displayName);
    console.log("user login gmail", user.uid);
    return { id: user.uid, displayName: user.displayName };
  }
);

const initialState = {
  status: "idel",
  userName: "",
  error: "",
  userId: null,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Definir tus acciones síncronas aquí
    setUserName: (state, action) => {
      state.userName = action.payload.displayName;
      state.userId = action.payload.id;
    },

    clearUserName: (state) => {
      state.userName = "";
      state.userId = "";
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = "";
    },
    setLoadin: (state) => {
      state.status = "loading";
    },
    clearLoading: (state, action) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) =>
    builder
      // SignUp
      .addCase(SignUpFirebase.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(SignUpFirebase.fulfilled, (state, action) => {
        state.userId = action.payload.id;
        state.userName = action.payload.displayName;
        state.status = "idel";
        state.error = "";
      })
      .addCase(SignUpFirebase.rejected, (state, action) => {
        state.status = "error";
        state.error = getErrorMessageInPortuguese(action.error.code);
      })
      // SignIn
      .addCase(loginFirebase.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loginFirebase.fulfilled, (state, action) => {
        state.userId = action.payload.id;
        state.status = "idel";
        state.error = "";
      })
      .addCase(loginFirebase.rejected, (state, action) => {
        state.status = "error";
        state.error = getErrorMessageInPortuguese(action.error.code);
      })
      // SignInWithGoogle
      .addCase(loginFirebaseWithGoogle.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loginFirebaseWithGoogle.fulfilled, (state, action) => {
        state.userId = action.payload.id;
        state.userName = action.payload.displayName;
        state.status = "idel";
        state.error = "";
      })
      .addCase(loginFirebaseWithGoogle.rejected, (state, action) => {
        state.status = "error";
        state.error = getErrorMessageInPortuguese(action.error.code);
      }),
});

export default userSlice.reducer;

// Exportar las acciones sincronas
export const {
  setUserName,
  clearUserName,
  setError,
  clearError,
  setLoadin,
  clearLoading,
} = userSlice.actions;
