import axios from "axios";

const getResponsePrice = async (cepDestino, valorDeclarado) => {
  const options = {
    method: "GET",
    url: `https://radiant-puppy-86ffa0.netlify.app/api/get-price?cepDestino=${cepDestino}&valorDeclarado=${valorDeclarado}`,
  };
  const response = await axios.request(options);
  const data = response.data;
  return data;
};
const getResponseDeliveryTime = async (cepDestino) => {
  const options = {
    method: "GET",
    url: `https://radiant-puppy-86ffa0.netlify.app/api/prazo-entrega?cepDestino=${cepDestino}`,
  };

  const response = await axios.request(options);
  const data = response.data;
  return data;
};

const getFrete = async (cepDestino, valorDeclarado) => {
  // crear una condicion verificar cepDestino con el del cliente
  let priceResponse, deliveryTimeResponse;

  // Intentar obtener la respuesta de la función 1 con un máximo de 5 intentos
  const maxAttemptsFunction1 = 5;
  let attemptsFunction1 = 0;

  while (!priceResponse && attemptsFunction1 < maxAttemptsFunction1) {
    try {
      priceResponse = await getResponsePrice(cepDestino, valorDeclarado);
    } catch (error) {
      console.error(
        `Error en la solicitud de precio (${
          attemptsFunction1 + 1
        }/${maxAttemptsFunction1}): ${error.message}`
      );
      attemptsFunction1++;
      // Puedes agregar un breve retraso antes de realizar el siguiente intento si es necesario
      // await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  // Si se obtiene la respuesta de la función 1, intentar obtener la respuesta de la función 2 con un máximo de 5 intentos
  if (priceResponse) {
    const maxAttemptsFunction2 = 5;
    let attemptsFunction2 = 0;

    while (!deliveryTimeResponse && attemptsFunction2 < maxAttemptsFunction2) {
      try {
        deliveryTimeResponse = await getResponseDeliveryTime(cepDestino);
      } catch (deliveryError) {
        console.error(
          `Error en la solicitud de tiempo de entrega (${
            attemptsFunction2 + 1
          }/${maxAttemptsFunction2}): ${deliveryError.message}`
        );
        attemptsFunction2++;
        // Puedes agregar un breve retraso antes de realizar el siguiente intento si es necesario
        // await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  }

  // Continuar con el procesamiento de las respuestas (si se obtuvieron)
  if (priceResponse && deliveryTimeResponse) {
    const valorPAC = parseFloat(
      priceResponse.pac.vlBaseCalculoImposto.replace(",", ".")
    );
    const valorSedex = parseFloat(
      priceResponse.sedex.vlBaseCalculoImposto.replace(",", ".")
    );
    const prazoEntregaPAC = parseInt(deliveryTimeResponse.pac.prazoEntrega + 4);
    const prazoEntregaSedex = parseInt(
      deliveryTimeResponse.sedex.prazoEntrega + 2
    );

    return { valorPAC, valorSedex, prazoEntregaPAC, prazoEntregaSedex };
  } else {
    throw Error(
      "Não foi possível obter o preço do frete no momento. Por favor, tente novamente mais tarde."
    );
  }
};

export default getFrete;
