import React, { useEffect, useState } from "react";
import "./ProductImage.scss"; // Importa los estilos de SCSS

const ProductImage = ({ product }) => {
  const [currentImage, setCurrentImage] = useState(product.image);
  // const [showDots, setShowDots] = useState(false);
  const handleImageHover = (newImage) => {
    setCurrentImage(newImage);
  };
  const handleNextImage = () => {
    const currentIndex = product.hoverImages.indexOf(currentImage);
    const nextIndex = (currentIndex + 1) % product.hoverImages.length;
    setCurrentImage(product.hoverImages[nextIndex]);
  };

  const handlePrevImage = () => {
    const currentIndex = product.hoverImages.indexOf(currentImage);
    const prevIndex =
      (currentIndex - 1 + product.hoverImages.length) %
      product.hoverImages.length;
    setCurrentImage(product.hoverImages[prevIndex]);
  };
  useEffect(() => {
    // Este efecto se ejecutará cuando cambie la prop 'product'
    setCurrentImage(product.image);
  }, [product]);
  return (
    <div className="product-image-container">
      <div className="main-image">
        <img
          src={require(`../../../img/img_product/${currentImage}`)}
          alt={product.title}
        />
        <div className="arrow-buttons">
          <button className="arrow-button arrow-left" onClick={handlePrevImage}>
            &lt;
          </button>
          <button
            className="arrow-button arrow-right"
            onClick={handleNextImage}
          >
            &gt;
          </button>
        </div>
      </div>
      {product.hoverImages && (
        <div className="hover-images">
          {product.hoverImages.map((image, index) => (
            <img
              className={`hover-images-img ${
                currentImage === image ? "active" : null
              }`}
              key={index}
              src={require(`../../../img/img_product/${image}`)}
              alt={`Product Variant ${index + 1}`}
              onMouseEnter={() => handleImageHover(image)}
            />
          ))}

          {product.hoverImages.map((image, index) => (
            <div
              className={`dot ${currentImage === image ? "active" : ""}`}
              key={index}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductImage;
