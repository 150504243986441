import React, { useState } from "react";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

import "./ChangePasswordForm.scss";

function ChangePasswordForm() {
  const auth = getAuth();
  const user = auth.currentUser;

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Nuevo estado para mensaje exitoso

  const changePasswordFunction = async () => {
    try {
      const credentials = EmailAuthProvider.credential(
        user.email,
        currentPassword
      ); // Create credential for reauthentication
      await reauthenticateWithCredential(user, credentials); // Reauthenticate the user with current password

      await updatePassword(user, newPassword); // Update the password
      setErrorMessage("");
      setSuccessMessage("Senha alterada com sucesso!");
    } catch (error) {
      console.log(error);
      setErrorMessage("Falha ao alterar a senha. Verifique suas credenciais.");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage("Novas senhas não combinam.");
      return;
    }

    if (!user) {
      setErrorMessage("Usuário não autenticado.");
      return;
    }

    await changePasswordFunction();
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div className="change-password-form">
      <h2 className="text-4xl py-16">Alterar a senha da sua conta</h2>
      <form onSubmit={handleFormSubmit} className="password-form">
        <label htmlFor="current-password">Senha atual:</label>
        <input
          type="password"
          id="current-password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
        />
        <label htmlFor="new-password">Nova senha:</label>
        <input
          type="password"
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <label htmlFor="confirm-password">Confirme a nova senha:</label>
        <input
          type="password"
          id="confirm-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <button
          type="submit"
          className="inline-flex text-white bg-slate-900 border-0 py-2 px-6 focus:outline-none hover:bg-neutral-400 rounded text-lg uppercase"
        >
          Alterar a senha
        </button>
      </form>
    </div>
  );
}

export default ChangePasswordForm;
