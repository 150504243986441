import React, { useState } from "react";
import CustomButton from "../../utils/custom-button/custom-button";
import FormInput from "../../utils/form-input/form-input";
import "./sign-in.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loginFirebase,
  loginFirebaseWithGoogle,
} from "../../../Store/UserSlice";
const SignIn = () => {
  const navigate = useNavigate(); // Obtén la función navigate
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const erroSignIn = useSelector((state) => state.user.error);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail("");
    setPassword("");
    try {
      await dispatch(loginFirebase({ email, password })).unwrap();
      navigate("/products");
    } catch (err) {
      console.log(err);
    }
  };
  const handleSignInWithGoogle = async () => {
    try {
      await dispatch(loginFirebaseWithGoogle()).unwrap();
      navigate("/products");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="sign-in">
      <p className="form-title-sin-in-up">Entre com seu e-mail e senha</p>
      <form onSubmit={handleSubmit}>
        <FormInput
          name="email"
          type="email"
          value={email}
          handleChange={handleChange}
          label="Email"
          required
        />
        <FormInput
          name="password"
          type="password"
          value={password}
          handleChange={handleChange}
          label="Senha"
          required
        />
        <div className="buttons">
          <CustomButton type="submit">Entrar</CustomButton>
          <CustomButton isGoogleSignIn onClick={handleSignInWithGoogle}>
            Login Google
          </CustomButton>
        </div>
        <div className="redefinir-senha">
          <Link to="/redefinir-senha" title="Esqueceu sua senha?">
            <span className="redefinir-senha-span">Esqueceu sua senha?</span>
          </Link>
        </div>
        {erroSignIn && (
          <p className="text-red-500 mt-2 text-sm">{erroSignIn}</p>
        )}
      </form>
    </div>
  );
};

export default SignIn;
