import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// NavLink
import { auth } from "../../../firebase/dbAccess";
import "./header.scss";
import iconTuareg from "../../../img/iconTureg.png";
import { RiMenuFill, RiCloseLine } from "react-icons/ri";

import MiniCart from "../MiniCart/MiniCart";
// import { LogoSVG } from "../../utils/LogoSVG";
function obtenerPrimeraPalabra(nombreCompleto) {
  if (nombreCompleto) {
    const palabras = nombreCompleto.split(" ");

    if (palabras.length === 1) {
      return nombreCompleto; // Devuelve el nombre completo si solo hay un nombre
    } else {
      return palabras[0]; // Devuelve la primera palabra si hay más de un nombre
    }
  }
}
const navigation = [
  {
    name: "Início",
    path: "/",
  },
  {
    name: "Produtos",
    path: "/products",
  },
  {
    name: "Sobre nós",
    path: "/sobre-nos",
  },
  {
    name: "Contacte-nos",
    path: "/contact",
  },
];
const Menu = ({ setToggleMenu }) =>
  navigation.map((navigation, index) => {
    return (
      <Link
        onClick={() => setToggleMenu(false)}
        key={index}
        to={navigation.path}
        className="uppercase mr-5 hover:text-gray-900 cursor-pointer"
      >
        {navigation.name}
      </Link>
    );
  });
const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const userName = useSelector((state) => state.user.userName);

  // const navigate = useNavigate();

  useEffect(() => {
    if (userName) {
      setShowMenu(false); // Restablecer showMenu a false al iniciar sesión
    }
  }, [userName]);
  const handleLinkClick = () => {
    setShowMenu(false);
  };
  useEffect(() => {
    const closeMenuOnClickOutside = (e) => {
      if (showMenu && !e.target.closest(".user-menu-container")) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", closeMenuOnClickOutside);

    return () => {
      window.removeEventListener("click", closeMenuOnClickOutside);
    };
  }, [showMenu]);
  return (
    <header className="text-gray-600 body-font shadow-lg">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <Link
          to={"/"}
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
        >
          {/* <span className="ml-3 text-xl font-serif">Tuareg</span> */}
          <div className="logo-container w-32 h-12 cursor-pointer ">
            <img className="w-[60px]" src={iconTuareg} alt="" />
          </div>
        </Link>

        <nav className=" md:ml-auto flex flex-wrap items-center text-base justify-end">
          <div className="tuareg__navbar-links_container">
            <Menu setToggleMenu={setToggleMenu} />
          </div>
          {userName ? (
            <>
              <MiniCart />
              <div className="user-menu-container">
                <div
                  className="mr-5 hover:text-gray-900 cursor-pointer"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  Olá {obtenerPrimeraPalabra(userName)}
                </div>
                {showMenu && (
                  <div className="dropdown-menu">
                    <Link
                      to="/painel-do-usuario"
                      className="option"
                      onClick={handleLinkClick}
                    >
                      Minha Conta
                    </Link>
                    <Link
                      to="/detalhes-da-compra"
                      className="option"
                      onClick={handleLinkClick}
                    >
                      Detalhes da compra
                    </Link>
                    <div className="option" onClick={() => auth.signOut()}>
                      Fechar sessão
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <Link
              className="uppercase mr-5 hover:text-gray-900 cursor-pointer"
              to="/iniciar-sessao"
            >
              Iniciar sessão
            </Link>
          )}
          <div className="tuareg__navbar-menu">
            {toggleMenu ? (
              <RiCloseLine
                color="#000"
                size={27}
                onClick={() => setToggleMenu(false)}
              />
            ) : (
              <RiMenuFill
                color="#000"
                size={27}
                onClick={() => setToggleMenu(true)}
              />
            )}
            {toggleMenu && (
              <div className="tuareg__navbar-menu_container scale-up-center">
                <div className="tuareg__navbar-menu_container-links">
                  <Menu setToggleMenu={setToggleMenu} />
                </div>
              </div>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
