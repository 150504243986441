import React from "react";
import { Link } from "react-router-dom";
import "./ConfirmationPage.scss";
import FloatingWhatsAppIcon from "../../components/utils/FloatingWhatsAppIcon/FloatingWhatsAppIcon";

const ConfirmationPage = () => {
  return (
    <div className="confirmation-page">
      <div className="confirmation-container">
        <div className="confirmation-content">
          <h1 className="confirmation-title">Obrigado pela sua compra!</h1>
          <p className="confirmation-message">
            Seu pedido foi recebido com sucesso e está sendo processado.
          </p>
          <p className="confirmation-details">
            Agradecemos por escolher nossos produtos. Em breve, você receberá um
            e-mail com informações detalhadas sobre seu pedido, incluindo o
            status de envio.
          </p>
          <p className="confirmation-reminder">
            Enquanto isso, continue explorando nossos produtos e fique à vontade
            para entrar em contato caso tenha alguma dúvida. Esperamos que
            aproveite sua compra!
          </p>
        </div>
        <div className="back-container mt-8">
          <Link to="/products" className="back-link">
            Continue explorando nossos produtos
          </Link>
        </div>
      </div>
      <FloatingWhatsAppIcon />
    </div>
  );
};

export default ConfirmationPage;
