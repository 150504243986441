import React, { useState } from "react";
import { ReactComponent as ShoppingIcon } from "../../../img/shopping-bag.svg";
import { Link } from "react-router-dom";
import { FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getTotalCartPice, removeFromCart } from "../../../Store/CartSlice";
import { formattedPrice } from "../../utils/FormatCurrency";
import("./MiniCart.scss");
const MiniCart = () => {
  // const { shoppingCart, dispatch, totalPrice } = useContext(CartContext);
  const shoppingCart = useSelector((state) => state.cart.shoppingCart);
  const totalPrice = useSelector(getTotalCartPice);
  const dispatch = useDispatch();
  const totalDistinctProducts = Object.keys(shoppingCart).length;
  const [isCartVisible, setCartVisible] = useState(false);
  const handleRemoveItem = (itemId) => {
    // dispatch({ type: "REMOVE_FROM_CART", id: itemId });
    dispatch(removeFromCart({ id: itemId }));
  };

  let hoverTimeout;

  const handleMouseEnter = () => {
    setCartVisible(true);
    clearTimeout(hoverTimeout);
  };

  const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => {
      setCartVisible(false);
    }, 300); // Ajusta el tiempo de retraso según tus necesidades (1000 ms = 1 segundo)
  };
  return (
    <div
      className="cart-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Icon Mini Cart */}
      <Link to="/dados-pessoais" className="cursor-pointer mr-5">
        <div className="cart-icon">
          <ShoppingIcon className="shopping-icon" />
          <span className="item-count">{totalDistinctProducts}</span>
        </div>
      </Link>
      {/* Icon Mini Cart */}
      {isCartVisible && shoppingCart.length > 0 && (
        <>
          <div className="mini_cart_inner">
            <div className="inner-single-block single-cart-item-loop">
              {shoppingCart.map((item) => (
                <div className="single-cart-item_loop" key={item.id}>
                  <div className="inner-single-block_item">
                    <div className="inner-single-block_cart-product">
                      {/* image container */}
                      <div className="inner-single-block_image-container">
                        <img
                          className="inner-single-block_image"
                          src={require(`../../../img/img_product/${item.product.image}`)}
                          alt="Nombre del producto"
                        />
                        <div className="inner-single-block_image-badge">
                          {item.quantity}x
                        </div>
                      </div>

                      {/* title and price */}
                      <div className="inner-single-block_description">
                        <h4>
                          <Link to={`products/${item.product.titleUrl}`}>
                            {item.product.title}
                          </Link>
                        </h4>
                        <span className="inner-single-block_price">
                          <p>
                            {parseFloat(item?.price).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </p>
                        </span>
                      </div>
                      {/* icon remove */}
                      <button
                        className="remove-btn"
                        onClick={() => handleRemoveItem(item.id)}
                      >
                        <FaTimesCircle />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="inner-single-block_total-price">
                <div className="cart-details">
                  <span className="label mr-2 inline-block mt-5 text-black font-bold">
                    Total:
                  </span>
                  <span className="value shopping-cart__total text-black font-bold">
                    <span
                      className="money"
                      data-currency-brl={totalPrice}
                      data-currency="BRL"
                    >
                      {formattedPrice(totalPrice)}
                    </span>
                  </span>
                </div>
              </div>
              <div className="inner-single-block_checkout">
                <Link
                  to="/dados-pessoais"
                  className="text-white bg-slate-900 border-0 py-2 px-6 focus:outline-none hover:bg-neutral-400 rounded-full mt-2 text-lg uppercase"
                >
                  Checkout
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MiniCart;
