import React from "react";
import Hero from "../../components/common/Hero";
import Content from "../../components/common/Content";
import Contact from "../../components/common/Contact";
import Gallery from "../../components/utils/Gallery";
import ProductsCard from "../../components/common/ProductsCard";
import { Helmet } from "react-helmet";
import { FiArrowRight } from "react-icons/fi";
import { Link, useLoaderData } from "react-router-dom";
import FloatingWhatsAppIcon from "../../components/utils/FloatingWhatsAppIcon/FloatingWhatsAppIcon";
import CorreiosSection from "../../components/common/CorreiosSection/CorreiosSection";
import("./home.scss");
const Home = () => {
  const products = useLoaderData();
  const selectedProducts = [
    "4",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
  ];

  return (
    <>
      <Helmet>
        <title>
          Tuareg - Bem-vindo à nossa Loja de Rolamentos de Qualidade
        </title>
        <meta
          name="description"
          content="Oferecemos uma ampla variedade de rolamentos de alta qualidade. Nossos produtos são confiáveis e ideais para aplicações industriais e automotivas"
        />
        <meta
          name="keywords"
          content="tuareg, rolamentos, esferas de aço, loja online"
        />
        <meta name="author" content="Tuareg Comercio Exterior" />
        <script
          src="https://www.google.com/recaptcha/api.js?hl=pt"
          async
          defer
        ></script>
      </Helmet>
      <Hero />
      {products.length > 0 ? (
        <>
          <div className="text-wrapper flex flex-wrap w-full mt-20 flex-col items-center text-center">
            <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
              Nossa seleção de rolamentos de alta qualidade
            </h2>
            <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
              Compre com confiança, sabendo que você está adquirindo rolamentos
              de confiança para suas aplicações industriais. Explore nossa
              variedade de rolamentos de aço cromo, adequados para motores
              elétricos, bombas, ventiladores e muito mais em nossa loja online.
            </p>
          </div>
          <ProductsCard
            products={products.filter((product) =>
              selectedProducts.includes(product.id)
            )}
          />
          <div className="flex justify-center text-lg">
            <Link
              to="/products"
              className="flex items-center sm:text-3xl hover:underline text-gray-900 mb-32 font-medium title-font"
            >
              <span> Ver todo o catálogo </span>
              <FiArrowRight className="ml-2" />
            </Link>
          </div>
        </>
      ) : (
        <div>Loading ...</div>
      )}
      <CorreiosSection />
      <div className="flex flex-wrap w-full mt-20 flex-col items-center text-center">
        <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
          Recursos e Benefícios
        </h2>
        <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
          Descubra os recursos e benefícios exclusivos dos nossos rolamentos de
          alta qualidade em nossa loja online.
        </p>
      </div>
      <Content />
      <Gallery />
      <Contact />
      <FloatingWhatsAppIcon />
    </>
  );
};

export default Home;
