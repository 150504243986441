import { createSlice } from "@reduxjs/toolkit";

// dataCustomerForm.js
const initialState = {
  dataCustomerObject: {
    docId: "",
    name: "",
    email: "",
    address: "",
    cpf: "",
    city: "",
    postalCode: "",
    phone: "",
    selectedRegion: "",
  },
};

const customerDataSlice = createSlice({
  name: "customerData",
  initialState,
  reducers: {
    setDataCustomer: (state, action) => {
      state.dataCustomerObject = action.payload;
    },
    clearDataCustomer: () => initialState,
  },
});
export default customerDataSlice.reducer;
export const { setDataCustomer, clearDataCustomer } = customerDataSlice.actions;
