import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ImLocation } from "react-icons/im";
import "./PrePurchaseShipping.scss";
import { AiOutlineClose } from "react-icons/ai";
import getFrete from "../../../services/apiFrete";
import { FaInfoCircle } from "react-icons/fa";
import freteImage from "../../../img/preshipping-img.png";
import Loader from "../../utils/Loader/Loader";
import { FormatCurrency } from "../../utils/FormatCurrency";
const PrePurchaseShipping = ({ product }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cep, setCep] = useState("");
  const [placeholder, setPlaceholder] = useState("Adicione seu CEP");
  const [freteInfo, setFreteInfo] = useState({});
  const handleFocus = () => {
    // Cambia el estado del placeholder cuando el input obtiene el foco
    setPlaceholder("_____-__");
  };

  const handleBlur = () => {
    // Cambia el estado del placeholder cuando el input pierde el foco
    setPlaceholder("Adicione seu CEP");
  };

  const handleCepChange = (event) => {
    let value = event.target.value;

    // Elimina cualquier caracter que no sea un número
    value = value.replace(/\D/g, "");

    // Formatea el CEP como "_____-__"
    if (value.length > 5) {
      value = `${value.slice(0, 5)}-${value.slice(5, 8)}`;
    }
    // Actualiza el estado con el nuevo valor formateado
    setCep(value);
    localStorage.setItem("storedCep", value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setModalIsOpen(true);
    // console.log("cep", cep);
    if (cep) {
      const CEPWithoutHyphen = cep.replace(/-/g, "");
      const freteResponse = await getFrete(CEPWithoutHyphen, 100);
      setFreteInfo(freteResponse);
      setLoading(false);
    }
    setLoading(false);
  };
  const closeModal = () => {
    // Cierra el modal
    setModalIsOpen(false);
    setFreteInfo({});
  };
  const customStyles = {
    content: {
      width: "50%", // Ajusta el ancho según tus necesidades
      height: "52%", // Ajusta la altura según tus necesidades
      margin: "auto", // Centra el modal en la pantalla
    },
  };
  const isButtonDisabled = cep.length !== 9;

  useEffect(() => {
    const storedCep = localStorage.getItem("storedCep");
    if (storedCep) {
      setCep(storedCep);
    }
  }, []);
  if (loading) return <Loader />;
  return (
    <div className="frete-container">
      <div className="shipping-container mt-12">
        <Tabs selectedTabClassName="selected-tab">
          <TabList>
            <Tab>Frete</Tab>
            <Tab>Detalhes técnicos</Tab>
          </TabList>
          <TabPanel>
            <div className="technical-details">
              <div className="shipping-title mb-8">
                <p className="text-xl text-center">
                  Verifique custo e prazo de entrega
                </p>
              </div>
              <div className="shipping-consult-wrapper">
                <form onSubmit={handleSubmit}>
                  <div className="shipping-col1 ">
                    <label>Adicionar CEP</label>
                    <div className="shipping-container-input flex rounded-2xl bg-neutral-100">
                      <span>
                        {" "}
                        <ImLocation
                          color="#2e4252"
                          size="1.5rem"
                          style={{ marginRight: ".625rem" }}
                        />
                      </span>
                      <input
                        className="shipping-input rounded-2xl bg-neutral-100"
                        type="text"
                        placeholder={placeholder}
                        value={cep}
                        onChange={handleCepChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="shipping-button mx-auto my-5 text-center">
                    <button
                      type="submit"
                      disabled={isButtonDisabled}
                      className={`py-2 px-6 focus:outline-none rounded ${
                        isButtonDisabled
                          ? "text-gray-500 bg-gray-300 cursor-not-allowed"
                          : "text-white bg-slate-900 hover:bg-neutral-400"
                      }`}
                      style={{ opacity: isButtonDisabled ? 0.5 : 1 }}
                    >
                      Consultar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            {product?.technicalDetails && (
              <div className="technical-details">
                <p className="technical-details-p">
                  Diâmetro interno: {product.technicalDetails.diâmetroInterno}
                </p>
                <p className="technical-details-p">
                  Diâmetro externo: {product.technicalDetails.diâmetroExterno}
                </p>
                <p className="technical-details-p">
                  Largura: {product.technicalDetails.largura}
                </p>
              </div>
            )}
          </TabPanel>
        </Tabs>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="Ejemplo de Modal"
        style={customStyles}
      >
        {/* Botón de cierre */}
        <button className="close-button" onClick={closeModal}>
          <AiOutlineClose className="close-icon" />
        </button>

        <div className="modal-content">
          <h2 className="mb-8 text-2xl text-center mt-4">
            Detalhes sobre Custos e Prazos de Entrega
          </h2>
          <p className="modal-purchase-detail bg-neutral-100 p-4 rounded text-lg text-slate-900">
            O frete é calculado com base no pedido completo, não importando
            quantas peças você escolha. Assim, você paga uma taxa fixa pelo
            envio, não por cada item individual
          </p>
          <div className="modal-info mb-12">
            <div className="text-container">
              <p className="text-lg font-semibold">
                Valor PAC: {FormatCurrency(freteInfo.valorPAC)}
              </p>
              <p className="text-sm text-gray-600">
                Prazo de entrega PAC: {freteInfo.prazoEntregaPAC} dias
              </p>
              <p className="text-lg font-semibold">
                Valor Sedex: {FormatCurrency(freteInfo.valorSedex)}
              </p>
              <p className="text-sm text-gray-600">
                Prazo de entrega Sedex: {freteInfo.prazoEntregaSedex} dias
              </p>
            </div>
            <div className="image-container-preshipping">
              <img
                className="image-frete"
                src={freteImage}
                alt="Frete Pac Sedex"
              />
            </div>
          </div>
          <div className="flex items-center text-sm border border-gray-300 p-2 rounded mb-4">
            <FaInfoCircle className="text-gray-500 mr-2" />
            <p>
              Atenção: Os custos de envio e os prazos de entrega estão sujeitos
              aos serviços oferecidos pelos Correios Brasil.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PrePurchaseShipping;
