import React from "react";
import { useSelector } from "react-redux";

import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import ShippingForm from "../../components/common/ShippingForm/ShippingForm";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ChangePasswordForm from "../../components/common/ChangePasswordForm/ChangePasswordForm";
import("./UserDashboard.scss");
function UserDashboard() {
  const userId = useSelector((state) => state.user.userId);
  const handleFormSubmit = () => null;
  return (
    <div className="perfil-header">
      <div className="menu-container">
        <div className="menu">
          <div className="menu-item ">
            <FaUser className="user-icon" />
          </div>
          <div className="menu-item active">
            <span>Perfil de usuário</span>
          </div>
          <div className="separator">&#62;</div>
          <Link to="/detalhes-da-compra">
            <div className="menu-item ">
              <span>Compras feitas</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="tab-content-user">
        <Tabs>
          <TabList>
            <Tab>Dados pessoais</Tab>
            <Tab>Alterar senha</Tab>
          </TabList>
          <TabPanel>
            <div className="cart-page">
              <ShippingForm
                buttonAtribute="Cadastrar dados"
                currentUserId={userId}
                onSubmitForm={handleFormSubmit}
              />
            </div>
          </TabPanel>

          <TabPanel>
            <ChangePasswordForm />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default UserDashboard;
