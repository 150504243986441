import React from "react";
import { FaWhatsapp } from "react-icons/fa"; // Importa el ícono de WhatsApp desde react-icons
import "./FloatingWhatsAppIcon.css";

const FloatingWhatsAppIcon = () => {
  const phoneNumber = "+5545999628659";
  const message =
    "Olá! Estou interessado nos rolamentos disponíveis em sua loja.";
  const handleClick = () => {
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="floating-whatsapp-icon-container" onClick={handleClick}>
      <FaWhatsapp size={30} color="#ffffff" />
    </div>
  );
};

export default FloatingWhatsAppIcon;
