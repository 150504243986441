import React from "react";
import {
  FaCheckCircle,
  FaShieldAlt,
  FaLock,
  FaSpinner,
  FaCogs,
  FaUserFriends,
} from "react-icons/fa";

const Content = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -m-4">
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-neutral-200 text-slate-900 mb-4">
                <div className="icon">
                  <FaCheckCircle size={24} />
                </div>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Desempenho Superior
              </h2>
              <p className="leading-relaxed text-base">
                Garantimos um desempenho superior dos nossos rolamentos em todas
                as condições, proporcionando confiabilidade e durabilidade
                excepcionais.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-neutral-200 text-slate-900 mb-4">
                <div className="icon">
                  <FaShieldAlt size={24} />
                </div>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Alta Resistência
              </h2>
              <p className="leading-relaxed text-base">
                Nossos rolamentos possuem alta resistência para suportar cargas
                pesadas, garantindo a integridade estrutural mesmo em condições
                extremas.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-neutral-200 text-slate-900 mb-4">
                <div className="icon">
                  <FaLock size={24} />
                </div>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Vedação Eficiente
              </h2>
              <p className="leading-relaxed text-base">
                Contamos com vedação eficiente que protege os rolamentos contra
                poeira, umidade e contaminação, prolongando sua vida útil.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-neutral-200 text-slate-900 mb-4">
                <div className="icon">
                  <FaSpinner size={24} />
                </div>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Rotação Suave
              </h2>
              <p className="leading-relaxed text-base">
                Nossos rolamentos oferecem rotação suave e silenciosa, reduzindo
                o desgaste e o ruído para um desempenho ótimo.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-neutral-200 text-slate-900 mb-4">
                <div className="icon">
                  <FaCogs size={24} />
                </div>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Aplicações Variadas
              </h2>
              <p className="leading-relaxed text-base">
                Nossos rolamentos são versáteis e adequados para uma ampla gama
                de aplicações, incluindo motores elétricos, bombas e
                transmissões mecânicas.
              </p>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-200 p-6 rounded-lg">
              <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-neutral-200 text-slate-900 mb-4">
                <div className="icon">
                  <FaUserFriends size={24} />
                </div>
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Atendimento Personalizado
              </h2>
              <p className="leading-relaxed text-base">
                Equipe dedicada pronta para ajudar com suas necessidades
                específicas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
