import React from "react";
import { LogoSVG } from "../../utils/LogoSVG";
import { Link } from "react-router-dom";
import("./hero.scss");
const Hero = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
        <div className="img-hero-container">
          <LogoSVG />
        </div>
        <div className="text-center lg:w-2/3 w-full">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            Rolamentos de Aço Cromo: Resistência e Durabilidade
          </h1>
          <p className="mb-8 leading-relaxed">
            Na nossa loja online de rolamentos, você encontrará uma ampla
            seleção de produtos de alta qualidade para atender às suas
            necessidades. Nossos rolamentos Tuareg são fabricados com materiais
            premium e projetados para oferecer desempenho excepcional e
            durabilidade.
          </p>
          <div className="flex justify-center">
            <Link
              to={"/contact"}
              className="inline-flex text-white bg-slate-900 border-0 py-2 px-6 focus:outline-none hover:bg-neutral-400 rounded text-lg uppercase"
            >
              Contacte-nos
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
