import { LazyLoadImage } from "react-lazy-load-image-component";
import "./slideshow.scss";

//

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Slideshow = ({ products }) => {
  // Función para copiar y ordenar aleatoriamente el array
  const shuffleArray = (array) => {
    const newArray = [...array]; // Copia el array original
    let currentIndex = newArray.length,
      randomIndex,
      temporaryValue;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      temporaryValue = newArray[currentIndex];
      newArray[currentIndex] = newArray[randomIndex];
      newArray[randomIndex] = temporaryValue;
    }

    return newArray;
  };

  const shuffledProducts = shuffleArray(products);

  return (
    <section className="body-font overflow-hidden section-gallery mx-auto">
      <div className="container px-5 py-8 mx-auto">
        <h2 className="text-gray-900 text-3xl title-font font-medium">
          Outros produtos
        </h2>
        <Carousel responsive={responsive} className="carousel">
          {shuffledProducts.map((item) => (
            <Link
              to={`/products/${item.titleUrl}`}
              key={item.id}
              className="cursor-pointer"
            >
              <div className="carousel__item" key={item.id}>
                <LazyLoadImage
                  src={require(`../../../img/img_product/${item.image}`)}
                  alt={`Imagen de ${item.title}`}
                  className="product-image"
                />
                <h3 className="product-price">
                  {parseFloat(item?.price).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </h3>
                <h3 className="product-title">{item.title}</h3>
              </div>
            </Link>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Slideshow;
