import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { AiOutlineMail } from "react-icons/ai"; // Importar el icono de React
import "./ResetPassword.scss";

const ResetPasswordComponent = () => {
  const [email, setEmail] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState(null);

  const handleResetPassword = () => {
    const auth = getAuth();

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetSent(true);
        setError(null);
      })
      .catch((error) => {
        const errorMessage = error.message;
        setError(errorMessage);
      });
  };

  return (
    <div className="container-reset">
      <div className="max-w-2xl  mx-auto px-4 reset-password-container">
        <h2 className="reset-password-title">
          Passos por E-mail para Configurar uma Nova Senha
        </h2>

        <p className="reset-password-paragraph mb-8">
          Para redefinir sua senha, enviaremos um e-mail para o endereço
          registrado. Por favor, siga as instruções no e-mail para criar uma
          nova senha segura. Se você não receber o e-mail em alguns minutos,
          verifique sua pasta de spam ou lixo eletrônico.
        </p>
        {resetSent ? (
          <p className="reset-password-message">
            Check your email for password reset instructions.
          </p>
        ) : (
          <div className="reset-password-form">
            <AiOutlineMail className="email-icon" /> {/* Icono de React */}
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="email-input"
            />
            <button
              onClick={handleResetPassword}
              className="reset-button bg-slate-900"
            >
              Reset Password
            </button>
            {error && <p className="error-message">Error: {error}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordComponent;
