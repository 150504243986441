export const LogoSVG = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 551.000000 215.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,215.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M2180 2099 c-135 -4 -420 -12 -635 -18 -1017 -29 -1448 -43 -1405
-48 35 -3 699 -23 1120 -33 195 -5 528 -14 740 -20 428 -13 1559 -8 1910 9
118 6 402 15 630 21 663 17 876 23 865 26 -9 3 -283 11 -1410 44 -187 6 -468
14 -625 20 -315 11 -814 10 -1190 -1z"
        />
        <path
          d="M390 1567 c0 -30 -2 -90 -6 -133 l-5 -79 19 50 c28 74 39 92 75 118
43 33 66 39 150 38 50 0 75 -5 86 -16 14 -14 16 -66 16 -410 0 -449 5 -424
-85 -433 -28 -2 -49 -6 -47 -8 8 -9 387 -11 387 -3 0 5 -18 9 -41 9 -41 0 -78
14 -91 34 -4 6 -8 187 -8 403 0 440 -3 422 73 425 153 6 213 -29 237 -138 19
-89 24 -80 30 56 4 74 6 136 5 138 -1 1 -18 -5 -38 -13 -53 -22 -678 -22 -721
0 -15 8 -30 15 -32 15 -2 0 -4 -24 -4 -53z"
        />
        <path
          d="M2331 1237 c-35 -84 -79 -190 -98 -237 -110 -264 -120 -281 -188
-297 -28 -6 -48 -12 -45 -13 3 -1 69 -2 148 -3 138 -2 179 4 106 16 -20 3 -42
11 -50 18 -24 20 -15 83 26 186 l39 98 100 3 100 3 30 -63 c101 -216 105 -248
30 -248 -10 0 -19 -3 -19 -7 0 -5 70 -8 155 -8 152 0 193 6 116 19 -28 4 -46
15 -65 41 -31 40 -147 281 -289 598 -11 26 -23 47 -26 47 -3 0 -35 -69 -70
-153z m55 -39 c4 -13 20 -50 35 -82 16 -33 29 -63 29 -68 0 -4 -36 -8 -80 -8
-44 0 -80 3 -80 8 0 8 56 148 65 163 10 16 23 10 31 -13z"
        />
        <path
          d="M4620 1366 c-122 -36 -191 -86 -242 -174 -32 -55 -33 -61 -33 -167 0
-97 3 -116 25 -160 32 -65 96 -127 168 -162 55 -27 63 -28 202 -28 111 0 159
4 204 18 l58 18 -3 77 c-4 134 -3 140 34 156 30 13 21 14 -116 15 -96 1 -146
-2 -142 -9 3 -5 26 -10 50 -10 74 0 75 -1 75 -101 0 -77 -3 -91 -23 -114 -18
-21 -34 -28 -82 -32 -210 -19 -336 109 -339 342 -2 198 94 319 254 320 38 0
84 -7 110 -18 52 -19 118 -83 148 -142 l21 -40 0 45 c0 25 -4 65 -7 89 -7 43
-9 45 -62 64 -70 25 -236 32 -300 13z"
        />
        <path
          d="M3000 1361 c-111 -5 -127 -7 -92 -14 24 -5 46 -17 52 -27 6 -11 10
-134 10 -299 0 -268 -1 -282 -20 -301 -11 -11 -33 -20 -50 -20 -16 0 -30 -3
-30 -7 0 -5 70 -8 155 -8 85 -1 155 3 155 7 0 4 -15 8 -34 8 -57 0 -66 23 -66
172 l0 128 45 2 c25 0 50 1 56 2 7 1 42 -48 80 -108 37 -60 89 -133 114 -163
l46 -53 100 0 c59 0 99 4 99 10 0 6 -10 10 -22 10 -39 1 -76 23 -132 78 -49
48 -186 239 -186 259 0 5 14 17 31 27 50 29 89 101 89 162 0 124 -77 150 -400
135z m258 -57 c23 -25 32 -44 33 -71 4 -105 -11 -156 -54 -183 -12 -7 -52 -15
-89 -18 l-68 -5 0 134 c0 174 12 193 110 181 23 -3 46 -15 68 -38z"
        />
        <path
          d="M1267 1349 c23 -6 47 -17 55 -26 10 -12 14 -74 18 -242 6 -251 8
-260 78 -334 104 -108 337 -104 430 9 54 65 62 106 62 318 0 225 7 252 75 271
35 11 24 12 -95 12 -118 0 -130 -1 -93 -11 23 -6 50 -18 58 -27 26 -25 38
-142 32 -296 -8 -195 -38 -263 -133 -299 -106 -40 -234 6 -283 101 -25 48 -26
56 -29 265 -1 118 1 221 6 228 5 7 31 19 58 27 46 12 40 13 -116 13 -126 0
-155 -2 -123 -9z"
        />
        <path
          d="M3688 1348 c73 -16 73 -17 70 -338 -3 -265 -4 -282 -22 -296 -11 -8
-34 -14 -52 -14 -18 0 -36 -4 -39 -10 -4 -7 90 -10 274 -10 154 0 282 3 284 8
9 15 18 172 10 172 -4 0 -17 -19 -28 -42 -43 -88 -150 -134 -258 -112 -60 13
-68 34 -65 184 l3 125 30 -1 c17 -1 72 -5 123 -9 l92 -7 0 31 c0 32 0 32 -42
27 -24 -3 -79 -7 -123 -8 l-80 -3 -3 126 c-2 112 0 129 17 150 17 21 27 23 93
23 104 0 141 -24 189 -124 l17 -35 1 88 1 87 -267 -1 c-196 -1 -256 -4 -225
-11z"
        />
        <path
          d="M2430 159 c-107 -4 -404 -12 -660 -18 -256 -6 -629 -16 -830 -22
-201 -6 -475 -14 -610 -18 l-245 -7 145 -7 c137 -6 370 -13 1280 -37 212 -5
502 -15 645 -20 332 -13 928 -13 1250 0 245 10 576 20 1340 40 752 20 756 22
95 41 -327 9 -797 22 -1045 28 -247 7 -549 16 -670 21 -245 11 -406 11 -695
-1z"
        />
      </g>
    </svg>
  );
};
