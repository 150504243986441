import React, { useRef, useState } from "react";
import FormInput from "../../utils/form-input/form-input";
import CustomButton from "../../utils/custom-button/custom-button";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SignUpFirebase, setError } from "../../../Store/UserSlice";
const SignUp = () => {
  const navigate = useNavigate(); // Obtén la función navigate
  const [verified, setVerified] = useState(false);
  const recaptchaRef = useRef();
  const [errorCaptcha, setErrorCaptcha] = useState(false);
  const erroSignUp = useSelector((state) => state.user.error);
  const handleOnChange = () => {
    // console.log("Captcha value:", value);
    setVerified(true);
    setErrorCaptcha(false);
  };
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!verified) {
      setErrorCaptcha(true);
      return;
    }
    if (password !== confirmPassword) {
      dispatch(setError("As senhas não correspondem"));
    }
    setDisplayName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    try {
      await dispatch(
        SignUpFirebase({
          email,
          password,
          displayName,
        })
      ).unwrap();
      navigate("/products");
    } catch (err) {
      console.log("error 5044", err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "displayName":
        setDisplayName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="sign-up">
      <p className="form-title-sin-in-up">Cadastre-se com seu e-mail e senha</p>
      <form className="sign-up-form" onSubmit={handleSubmit}>
        <FormInput
          type="text"
          name="displayName"
          value={displayName}
          onChange={handleChange}
          label="Nome completo"
          required
        />
        <FormInput
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          label="Email"
          required
        />
        <FormInput
          type="password"
          name="password"
          value={password}
          onChange={handleChange}
          label="Senha"
          required
        />
        <FormInput
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          label="Confirme sua senha"
          required
        />
        <ReCAPTCHA
          hl="pt"
          ref={recaptchaRef}
          sitekey="6LejlIQeAAAAAHAENqEosDSjyl9fadTfrd1pbd1i"
          onChange={handleOnChange}
          style={{ marginBottom: "10px" }}
        />
        <CustomButton type="submit">Inscrever-se</CustomButton>
        {erroSignUp && (
          <p className="text-red-500 mt-2 text-sm">{erroSignUp}</p>
        )}
        {errorCaptcha && <p className="text-red-500">Valide o captcha!</p>}
      </form>
    </div>
  );
};

export default SignUp;
