import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>
          Tuareg - Bem-vindo à nossa Loja de Rolamentos de Qualidade
        </title>
        <meta
          name="description"
          content="Oferecemos uma ampla variedade de rolamentos de alta qualidade. Nossos produtos são confiáveis e ideais para aplicações industriais e automotivas"
        />
        <meta name="keywords" content="tuareg, rolamentos, qualidade" />
        <meta name="author" content="Tuareg Comercio Exterior" />
      </Helmet>
      <div className="flex items-center justify-center mt-24 mb-24">
        <div className="max-w-2xl  mx-auto px-4">
          <h1 className="text-3xl font-semibold mb-8 text-center">
            Política de Privacidade - tuaregcomex.com
          </h1>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Introdução</h2>
            <p className="text-gray-700 leading-relaxed">
              Bem-vindo à política de privacidade do site tuaregcomex.com,
              operado pela marca Tuareg. Esta política destina-se a informar os
              usuários sobre como coletamos, usamos, compartilhamos e protegemos
              suas informações pessoais.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              2. Coleta de Informações
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Ao utilizar nosso site, podemos coletar informações pessoais
              fornecidas voluntariamente por você, como nome, endereço, número
              de telefone, endereço de e-mail, entre outras informações
              necessárias para processar e entregar seu pedido.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              3. Uso de Informações
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Utilizamos suas informações pessoais para:
            </p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Processar e entregar seus pedidos;</li>
              <li>Fornecer suporte ao cliente;</li>
              <li>Melhorar nossos serviços e produtos;</li>
              <li>Cumprir obrigações legais e regulatórias.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              4. Compartilhamento de Informações
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Não compartilhamos suas informações pessoais com terceiros,
              exceto:
            </p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Com parceiros de logística para entregar seus pedidos;</li>
              <li>Quando exigido por lei ou processo legal.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Segurança</h2>
            <p className="text-gray-700 leading-relaxed">
              Implementamos medidas de segurança para proteger suas informações
              pessoais contra acesso não autorizado, uso ou divulgação.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Cookies</h2>
            <p className="text-gray-700 leading-relaxed">
              Utilizamos cookies para melhorar sua experiência de navegação em
              nosso site, personalizar conteúdos e anúncios, e analisar o
              tráfego do site. Você pode optar por desativar os cookies em seu
              navegador, mas isso pode afetar a funcionalidade do site.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. Seus Direitos</h2>
            <p className="text-gray-700 leading-relaxed">
              Você tem o direito de:
            </p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Acessar suas informações pessoais;</li>
              <li>Corrigir informações imprecisas;</li>
              <li>Solicitar a exclusão de suas informações.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              8. Alterações na Política de Privacidade
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Reservamo-nos o direito de atualizar esta política de privacidade
              periodicamente. Recomendamos que você revise esta página
              regularmente para estar ciente de quaisquer alterações.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">9. Contato</h2>
            <p className="text-gray-700 leading-relaxed">
              Se você tiver dúvidas ou preocupações sobre esta política de
              privacidade, entre em contato conosco através do e-mail:{" "}
              <a
                href="mailto:adm@tuaregcomex.com
"
                className="text-blue-500 hover:underline"
              >
                adm@tuaregcomex.com
              </a>
              .
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
