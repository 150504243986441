import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ShippingForm from "../../components/common/ShippingForm/ShippingForm";
import AsideBlockSumary from "../../components/common/AsideBlockSumary/AsideBlockSumary";
import "./DadosPessoais.scss";
import {
  decreaseQuantity,
  getTotalCartPice,
  increaseQuantity,
  removeFromCart,
} from "../../Store/CartSlice";
import { useNavigate } from "react-router-dom";
import { formattedPrice } from "../../components/utils/FormatCurrency";

const DadosPessoais = () => {
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.userId);
  const shoppingCart = useSelector((state) => state.cart.shoppingCart);
  const totalPrice = useSelector(getTotalCartPice);

  const dispatch = useDispatch();
  // const { shoppingCart, dispatch, totalPrice } = useContext(CartContext);
  const handleIncreaseQty = (itemId) => {
    // dispatch({ type: "INCREASE_QUANTITY", id: itemId });
    dispatch(increaseQuantity({ id: itemId }));
  };
  const handleDecreaseQty = (itemId) => {
    // dispatch({ type: "DECREASE_QUANTITY", id: itemId });
    dispatch(decreaseQuantity({ id: itemId }));
  };
  const handleRemoveItem = (itemId) => {
    // dispatch({ type: "REMOVE_FROM_CART", id: itemId });
    dispatch(removeFromCart({ id: itemId }));
  };
  const handleFormSubmit = async (formData) => {
    navigate(`/comprar`);
  };
  return (
    <div className="min-h-screen max-w-screen-lg m-auto header-content">
      {shoppingCart.length === 0 ? (
        <div className="container-empty-cart mt-8 text-center">
          <p>Nenhum produto no carrinho.</p>
        </div>
      ) : (
        <>
          <ShippingForm
            buttonAtribute="Enviar dados"
            currentUserId={userId}
            onSubmitForm={handleFormSubmit}
          />
          {/* block Oreder Sumary */}
          <AsideBlockSumary
            shoppingCart={shoppingCart}
            handleDecreaseQty={handleDecreaseQty}
            handleIncreaseQty={handleIncreaseQty}
            handleRemoveItem={handleRemoveItem}
            formattedTotalPrice={formattedPrice(totalPrice)}
          />
          {/* End block Oreder Sumary */}
        </>
      )}
    </div>
  );
};

export default DadosPessoais;
