import React from "react";

import { BsFacebook, BsTwitterX, BsInstagram } from "react-icons/bs";

function SocialMediaIcons({ size, color }) {
  const iconSize = size; // Common size for icons
  const iconColor = color; // Main color of your theme

  return (
    <div className="flex justify-center items-center gap-x-3np">
      <a href="https://www.facebook.com/profile.php?id=61555749299755">
        <BsFacebook
          color={iconColor}
          size={iconSize}
          style={{ marginRight: ".625rem" }}
        />
      </a>

      <a href="https://twitter.com/TuaregRolamento">
        <BsTwitterX
          color={iconColor}
          size={iconSize}
          style={{ marginRight: ".625rem" }}
        />
      </a>
      <a href="https://br.linkedin.com/in/tuareg-rolamentos-b3217634">
        <BsInstagram color={iconColor} size={iconSize} />
      </a>
    </div>
  );
}

export default SocialMediaIcons;
