import React from "react";
import { Helmet } from "react-helmet";

import "./product.scss";
import Slideshow from "../../components/common/slideshow/slideshow";
import ProductImage from "../../components/common/Product-image/ProductImage";
import ProductDetails from "../../components/common/Product-details/ProductDetails";
import FloatingWhatsAppIcon from "../../components/utils/FloatingWhatsAppIcon/FloatingWhatsAppIcon";
import { useLoaderData } from "react-router-dom";
import { getProduct, getProducts } from "../../services/apiProducts";

const Product = () => {
  const { productSelected, products } = useLoaderData();
  const { product, limitedTitle, limitedDescription } = productSelected;

  return (
    <div>
      <Helmet>
        <title>{limitedTitle}</title>
        <meta name="description" content={limitedDescription} />
        <meta name="keywords" content="tuareg, rolamentos, esferas de aço" />
        <meta name="author" content="Tuareg Comercio Exterior" />
      </Helmet>
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="container-img-product lg:w-4/5 mx-auto flex ">
            {/* <img
              className="lg:w-1/2 w-full  lg:h-auto max-h-[600px] h-64 object-contain object-center rounded"
              src={require(`../../img/img_product/${product.image}`)}
              alt={product.title}
            /> */}
            <ProductImage product={product} />
            <ProductDetails product={product} titleUrl={product.titleUrl} />
          </div>
        </div>
      </section>

      <Slideshow products={products} />
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <h2 className="text-gray-900 text-3xl title-font font-medium mb-8">
            Descrição
          </h2>
          <p
            className="leading-relaxed mb-5 text-lg"
            dangerouslySetInnerHTML={{ __html: product.description }}
          ></p>
        </div>
      </section>
      <FloatingWhatsAppIcon />
    </div>
  );
};

export async function loader({ params }) {
  const productSelected = await getProduct(params.productID);
  const products = await getProducts();
  return { productSelected, products };
}

export default Product;
