import React from "react";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";
import Loader from "./components/utils/Loader/Loader";
import { useSelector } from "react-redux";
import TopBar from "./components/common/TopBar/Topbar";

const AppLayout = () => {
  const navigation = useNavigation();
  const isLoading = navigation.state === "loading";
  const isFetching = useSelector((state) => state.user.status) === "loading";

  return (
    <div className="flex-col ">
      {isLoading || isFetching ? <Loader /> : null}
      <TopBar />
      <Header />
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </div>
  );
};

export default AppLayout;
