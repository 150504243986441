import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ProductsCard = ({ products = [] }) => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -m-4">
          {products.map((product) => {
            const { id, title, titleUrl, price, category, image } = product;
            // Convertir el precio a formato de número
            const priceNumber = parseFloat(price);

            // Formatear el número con separador de coma y símbolo de moneda
            const formattedPrice = priceNumber.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            return (
              <Link
                to={`/products/${titleUrl}`}
                key={id}
                className="lg:w-1/4 md:w-1/2 p-4 w-full border border-opacity-50 cursor-pointer"
              >
                <div className="block relative h-48 rounded overflow-hidden">
                  <LazyLoadImage
                    alt={title}
                    className=" object-contain object-center w-full h-full block"
                    src={require(`../../../img/img_product/${image}`)}
                  />
                </div>
                <div className="mt-4">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 uppercase">
                    {category}
                  </h3>
                  <h2 className="text-gray-900 title-font text-lg font-medium">
                    {title}
                  </h2>
                  <p className="mt-1 text-md font-semibold">{formattedPrice}</p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProductsCard;
