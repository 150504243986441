import { configureStore } from "@reduxjs/toolkit";
import userReducer, {
  clearLoading,
  clearUserName,
  setLoadin,
  setUserName,
} from "./UserSlice";
import customerDataReducer, { setDataCustomer } from "./CustomerDataSlice";
import cartReducer, { loadCart, localStorageMiddleware } from "./CartSlice";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db, firestore } from "../firebase/dbAccess";
import { collection, getDocs, query, where } from "firebase/firestore";

const store = configureStore({
  reducer: {
    user: userReducer,
    customerData: customerDataReducer,
    cart: cartReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});
export default store;
// Auth Monitor => Monitors the user in case of connection/disconnection
const auth = getAuth();
const unsubscribe = onAuthStateChanged(auth, async (user) => {
  if (user) {
    store.dispatch(setLoadin());

    const userDoc = await firestore.doc(`users/${user.uid}`).get();
    if (userDoc.exists) {
      const displayName = userDoc.data().displayName;
      store.dispatch(setUserName({ displayName, id: user.uid }));
      loadDatafromFirebase(user.uid);
      loadCartToLocalStorage(user.uid);
      store.dispatch(clearLoading());
    } else {
      console.log("El documento no existe");
    }
  } else {
    store.dispatch(clearUserName());
  }
  return () => unsubscribe();
});
// End Auth Monitor

// Loading data from firebase to CustomerDataSliceReducer
const loadDatafromFirebase = async (userId) => {
  async function getData(db) {
    const dataCol = collection(db, "DadosPessoaisClt");
    const userData = query(dataCol, where("id_usuario", "==", userId));

    const dataSnapshot = await getDocs(userData);
    const dataList = dataSnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    return dataList;
  }

  async function fetchData() {
    try {
      const dataUser = await getData(db);
      const dataCustomerInfo = dataUser[0]?.data?.formData;
      if (dataCustomerInfo && dataUser.length > 0) {
        store.dispatch(
          setDataCustomer({
            docId: dataUser[0]?.id,
            name: dataCustomerInfo.name,
            email: dataCustomerInfo.email,
            address: dataCustomerInfo.address,
            cpf: dataCustomerInfo.cpf,
            city: dataCustomerInfo.city,
            postalCode: dataCustomerInfo.postalCode,
            phone: dataCustomerInfo.phone,
            selectedRegion: dataCustomerInfo.selectedRegion,
          })
        );
      }
    } catch (error) {
      console.log("Problem to reach data:", error);
    }
  }
  fetchData();
};
// Loadind shopping cart data from Local Storage when user conected
export const loadCartToLocalStorage = (userId) => {
  const cartKey = `cart_${userId}`; // Asignar una clave única basada en el usuario
  const storedCart = localStorage.getItem(cartKey);
  if (storedCart) {
    store.dispatch(loadCart(JSON.parse(storedCart)));
  }
};
