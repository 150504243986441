import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaShoppingCart } from "react-icons/fa"; // Importa iconos de FontAwesome

import "./PopUpAddProduct.scss";

const PopUpAddProduct = ({ onClose, onGoToCart }) => {
  const navigate = useNavigate();
  const goToCart = () => {
    navigate("/dados-pessoais"); // Redirige al carrito de compra
    onClose(); // Cierra el popup
  };

  useEffect(() => {
    // Bloquear el desplazamiento cuando el popup se monta
    document.body.style.overflow = "hidden";

    return () => {
      // Restaurar el desplazamiento cuando el popup se desmonta
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="popup">
      <div className="popup-content">
        <FaCheckCircle size={48} color="#28a745" />
        <p>Produto adicionado ao carrinho.</p>
        <div className="popup-buttons">
          <button className="popup-button" onClick={goToCart}>
            <FaShoppingCart /> Ir ao carrinho
          </button>
          <button className="popup-button" onClick={onClose}>
            Continuar comprando
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpAddProduct;
