import React, { useState } from "react";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../firebase/dbAccess";
import { Timestamp } from "firebase/firestore";

function ContactSection() {
  const recaptchaRef = useRef();
  const mapSRC =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.865700589689!2d-54.23959142368064!3d-25.342287229428724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f6af40e7a19659%3A0x87b6115546bc87a9!2sR.%20T%C3%B4rres%20-%20S%C3%A3o%20Miguel%20do%20Igua%C3%A7u%2C%20PR%2C%2085877-000!5e0!3m2!1sen!2sbr!4v1684096010371!5m2!1sen!2sbr";

  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [mensajeEnviado, setMensajeEnviado] = useState(false);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  // Funciones para establecer límite de carácteres
  const handleNombreChange = (event) => {
    const { value } = event.target;
    // Limitar el número máximo de caracteres a 50
    if (value.length <= 50) {
      setNombre(value);
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    // Limitar el número máximo de caracteres a 100
    if (value.length <= 100) {
      setEmail(value);
    }
  };

  const handleMensajeChange = (event) => {
    const { value } = event.target;
    // Limitar el número máximo de caracteres a 500
    if (value.length <= 1000) {
      setMensaje(value);
    }
  };

  const handleOnChange = (value) => {
    // console.log("Captcha value:", value);
    setVerified(true);
    setError(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const fechaHoraActual = Timestamp.now();

    if (!verified) {
      setError(true);
      return;
    }
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      alert("Por favor insira um endereço de e-mail válido.");
      return;
    }
    try {
      // Agregar el documento a la colección "Mensajes" en Firestore
      await addDoc(collection(db, "Mensajes"), {
        Nombre: nombre,
        Email: email,
        Mensaje: mensaje,
        fechaHoraActual,
      });

      // Lógica adicional después de enviar el mensaje correctamente, como mostrar una notificación o redirigir a otra página
      setMensajeEnviado(true); // Mostrar el mensaje de éxito
      setNombre("");
      setEmail("");
      setMensaje("");
      setTimeout(() => {
        setMensajeEnviado(false);
      }, 5000);
    } catch (err) {
      // Manejo de errores si ocurre alguno al enviar el mensaje
      console.error("Error al enviar el mensaje:", err);
    }
    // Lógica para enviar el formulario o hacer cualquier otra acción
  };
  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          <iframe
            className="absolute inset-0"
            style={{ filter: "grayscale(1) contrast(1.2) opacity(0.4)" }}
            title="map"
            marginHeight="0"
            marginWidth="0"
            scrolling="no"
            src={mapSRC}
            width="100%"
            height="100%"
            frameBorder="0"
          ></iframe>
          <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
            <div className="lg:w-1/2 px-6">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                ENDEREÇO
              </h2>
              <p className="mt-1">Sao Miguel do Iguaçu - Rua Torres 587</p>
            </div>
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                Email
              </h2>
              <span className="text-indigo-500 leading-relaxed">
                adm@tuaregcomex.com
              </span>
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                Telefone
              </h2>
              <p className="leading-relaxed">+55 45 999628659</p>
            </div>
          </div>
        </div>
        <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
            Fale Conosco
          </h2>
          <p className="leading-relaxed mb-5 text-gray-600">
            Fique à vontade para entrar em contato conosco e obter mais
            informações sobre nossos produtos, formas de compra direta e
            qualquer outra dúvida que você tenha. Estamos aqui para ajudar!
          </p>
          <form
            action=""
            className="form-group custom-form"
            onSubmit={handleSubmit}
          >
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                Nome
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={nombre}
                onChange={handleNombreChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                required
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-neutral-400 focus:ring-2 focus:ring-neutral-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                required
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-600"
              >
                Mensagem
              </label>
              <textarea
                id="message"
                name="message"
                value={mensaje}
                onChange={handleMensajeChange}
                className="w-full bg-white rounded border border-gray-300 focus:border-neutral-400 focus:ring-2 focus:ring-neutral-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                required
              ></textarea>
              <p className="text-sm">
                Caracteres restantes: {1000 - mensaje.length} / {1000}
              </p>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LejlIQeAAAAAHAENqEosDSjyl9fadTfrd1pbd1i"
              onChange={handleOnChange}
            />
            <button
              type="submit"
              className="text-white mt-3 bg-slate-900 border-0 py-2 px-6 focus:outline-none hover:bg-neutral-400 rounded text-lg"
            >
              Enviar
            </button>
            {error && (
              <p className="text-red-500">
                Verifique o captcha antes de enviar o formulário.
              </p>
            )}
            {mensajeEnviado && (
              <div className="bg-green-200 text-green-700 p-2 mb-4">
                Mensagem enviada corretamente
              </div>
            )}
          </form>
          <p className="text-xs text-gray-500 mt-3">
            Estamos comprometidos em fornecer um atendimento ágil e eficiente.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
