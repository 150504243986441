import Home from "./modules/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Product, { loader as productLoader } from "./modules/Product";
import Products, { loader as productsLoader } from "./modules/Products";
import SobreNos from "./modules/SobreNos";
import ContateNos from "./modules/ContateNos";
import Error from "./modules/Error";
import SignInSignUp from "./modules/sign-in-sign-up/sign-in-sign-up";
import CartPage from "./modules/CartPage/CartPage";
import PrivateRoute from "./components/utils/PrivateRoute/PrivateRoute";
import ConfirmationPage from "./modules/ConfirmationPage/ConfirmationPage";
import PurchaseTracking from "./modules/purchase-tracking/PurchaseTracking";
import UserDashboard from "./modules/user-dashboard/UserDashboard";
import ResetPasswordComponent from "./modules/ResetPassword/ResetPassword";
import AppLayout from "./AppLayout";
import DadosPessoais from "./modules/DadosPessoais/DadosPessoais";
import PrivacyPolicy from "./modules/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./modules/TermsConditions/TermsConditions";

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />,
        loader: productsLoader,
        errorElement: <Error />,
      },
      {
        path: "/sobre-nos",
        element: <SobreNos />,
      },
      {
        path: "/contact",
        element: <ContateNos />,
      },
      {
        path: "/politica-privacidade",
        element: <PrivacyPolicy />,
      },
      {
        path: "/termos-condicoes",
        element: <TermsAndConditions />,
      },
      {
        path: "/iniciar-sessao",
        element: <SignInSignUp />,
      },
      {
        path: "/redefinir-senha",
        element: <ResetPasswordComponent />,
      },

      {
        path: "/products",
        element: <Products />,
        loader: productsLoader,
        errorElement: <Error />,
      },
      {
        path: "/products/:productID",
        element: <Product />,
        loader: productLoader,
        errorElement: <Error />,
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            path: "/dados-pessoais",
            element: <DadosPessoais />,
          },
          {
            path: "/comprar",
            element: <CartPage />,
            errorElement: <Error />,
          },
          {
            path: "/confirmation-page",
            element: <ConfirmationPage />,
          },
          {
            path: "/detalhes-da-compra",
            element: <PurchaseTracking />,
          },

          {
            path: "/painel-do-usuario",
            element: <UserDashboard />,
          },
        ],
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
