import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { useNavigate } from "react-router-dom";
import PopUpAddProduct from "../PopUpAddProduct/PopUpAddProduct";
import LoginPopUp from "../LoginPopUp/LoginPopUp";
import { addToCart } from "../../../Store/CartSlice";

import "./ProductDetails.scss";
import PrePurchaseShipping from "../PrePurchaseShipping/PrePurchaseShipping";
function ProductDetails({ product, titleUrl }) {
  const userName = useSelector((state) => state.user.userName);
  const [showPopup, setShowPopup] = useState(false); // Estado para controlar la visibilidad del popups
  const [showLoginSignup, setShowLoginSignup] = useState(false); // Estado para controlar la visibilidad del popups
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [clickedNum, setClickedNum] = useState(1);

  const handleDispatchAddToCart = (id, product) => {
    // console.log("before quantityParam", quantity);
    return dispatch(
      addToCart({
        id,
        product,
        quantity,
      })
    );
  };

  const handleAddToCart = () => {
    if (userName) {
      handleDispatchAddToCart(product?.id, product);
      setShowPopup(true);
    } else {
      setShowLoginSignup(true); // Mostrar el popup de inicio de sesión y registro
    }
  };

  const quantityDec = () => {
    if (quantity <= 1) return;

    setQuantity((quantity) => quantity - 1);
  };
  const quantityInc = () => {
    console.log("product.stock", product.stock);
    if (quantity >= product.stock) return;
    setQuantity((quantity) => quantity + 1);
  };

  return (
    <>
      <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
        <h2 className="text-sm title-font text-gray-500 tracking-widest">
          {product.category}
        </h2>
        <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
          {product.title}
        </h1>
        <span className="flex py-2 space-x-2 gap-1 mt-2">
          <FacebookShareButton
            url={`https://www.tuaregcomex.com/products/${titleUrl}`}
            quote={product.title}
          >
            <div className="text-gray-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </div>
          </FacebookShareButton>
          <TwitterShareButton
            url={`https://www.tuaregcomex.com/products/${titleUrl}`}
            title={product.title}
          >
            <div className="text-gray-500">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                viewBox="0 0 300.000000 300.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M33 2953 c19 -27 274 -390 567 -808 293 -418 541 -772 552 -787 l19
-28 -573 -652 c-316 -359 -577 -659 -582 -665 -6 -10 21 -13 122 -13 l129 0
509 579 c279 319 511 578 515 575 4 -2 188 -263 410 -579 l404 -575 442 0
c244 0 443 3 443 6 0 3 -268 388 -596 856 -328 467 -597 856 -598 863 0 7 233
280 520 607 286 326 535 610 553 631 l33 37 -133 -1 -134 0 -480 -549 c-264
-302 -482 -549 -485 -549 -3 -1 -178 246 -390 549 l-385 549 -448 1 -449 0 35
-47z m1663 -1450 c509 -713 928 -1303 931 -1310 4 -10 -34 -13 -194 -13 l-200
0 -929 1302 c-511 716 -930 1305 -932 1310 -2 4 87 8 197 8 l200 0 927 -1297z"
                  />
                </g>
              </svg>
            </div>
          </TwitterShareButton>
        </span>
        {/* Total Quantity */}
        <div className="flex items-center mt-4">
          <span className="text-gray-600 mr-3">Quantidade:</span>
          <button
            onClick={quantityDec}
            className="border text-gray-500 rounded-r rounded-full px-1 w-7"
          >
            -
          </button>
          <span className="text-gray-500 p-2">{quantity}</span>
          <button
            onClick={quantityInc}
            className="border text-gray-500 rounded-l rounded-full px-1 w-7"
          >
            +
          </button>
        </div>
        {/*  */}
        <div className="flex justify-between gap-4 flex-wrap items-center mt-8">
          <span className="title-font font-medium text-2xl text-gray-900">
            {parseFloat(product?.price).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
          <div className="flex">
            {product.stock === 0 ? (
              <div className="out-of-stock-message">
                <span className="icon">
                  <AiOutlineExclamationCircle size={24} color="#FF5733" />
                </span>
                Produto fora de estoque
              </div>
            ) : (
              <>
                <button
                  className="flex ml-auto text-white bg-slate-900 border-0 py-2 px-6 focus:outline-none hover:bg-neutral-400 rounded mr-2"
                  onClick={() => {
                    if (userName) {
                      // Agregar el producto al carrito
                      handleDispatchAddToCart(product?.id, product);

                      // Realiza otras acciones necesarias antes de redirigir

                      // Redirigir a la página del carrito de compra
                      navigate("/dados-pessoais");
                    } else {
                      setShowLoginSignup(true);
                    }
                  }}
                >
                  Comprar já
                </button>
                <button
                  onClick={handleAddToCart}
                  className="flex ml-auto border border-neutral-400 py-2 px-6 focus:outline-none hover:bg-slate-900 hover:text-white rounded"
                >
                  Adicionar
                </button>
                {showPopup && (
                  <PopUpAddProduct onClose={() => setShowPopup(false)} />
                )}

                {showLoginSignup && (
                  <LoginPopUp onClose={() => setShowLoginSignup(false)} />
                )}
              </>
            )}
          </div>

          {/* <button className="rounded-full w-10 h-10 bg-gray-200 p-0 border-0 inline-flex items-center justify-center text-gray-500 ml-4">
        <svg
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className="w-5 h-5"
        viewBox="0 0 24 24"
        >
        <path d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"></path>
        </svg>
      </button> */}
        </div>
        <PrePurchaseShipping product={product} />
        {/* <div className="product-availability">
      <div className="external-markets">
        <p>Você também pode comprar nossos produtos em:</p>
        <div className="environment">
          {product.urlMagalu ? <Magalu product={product} /> : null}
          {product.urlMercadolivre ? (
            <MercadoLivre product={product} />
          ) : null}
        </div>
      </div>
    </div> */}
      </div>
    </>
  );
}

export default ProductDetails;
