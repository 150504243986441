import React from "react";
import { Helmet } from "react-helmet";
import Contact from "../../components/common/Contact";
import FloatingWhatsAppIcon from "../../components/utils/FloatingWhatsAppIcon/FloatingWhatsAppIcon";
const ContateNos = () => {
  return (
    <div>
      <Helmet>
        <title>
          Tuareg - Bem-vindo à nossa Loja de Rolamentos de Qualidade
        </title>
        <meta
          name="description"
          content="Oferecemos uma ampla variedade de rolamentos de alta qualidade. Nossos produtos são confiáveis e ideais para aplicações industriais e automotivas"
        />
      </Helmet>
      <Contact />
      <FloatingWhatsAppIcon />
    </div>
  );
};

export default ContateNos;
