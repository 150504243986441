// Importa React e iconos
import React from "react";
import { FaEnvelope, FaPhone, FaBell } from "react-icons/fa";
import "./TopBar.scss"; // Importa tu archivo SCSS
import SocialMediaIcons from "../../utils/SocialMediaIcon/SocialMediaIcon";

// Componente de la top bar
const TopBar = () => {
  return (
    <div className="top-bar">
      <div className="top-bar__row">
        <div className="top-bar__contact-info">
          <span className="top-bar__contact-info-item">
            <i className="icon">
              <FaEnvelope />
            </i>{" "}
            adm@tuaregcomex.com
          </span>
          <span className="top-bar__contact-info-item">
            <i className="icon">
              <FaPhone />
            </i>{" "}
            +55 45 999628659
          </span>
        </div>
        <div className="top-bar__offer">
          <i className="icon">
            <FaBell />
          </i>
          <div className="top-bar__contact-info-item">
            <p className="top-bar__text-wrapp">
              Frete grátis na região de Foz do Iguaçu em compras acima de R$ 35
            </p>
          </div>
        </div>
        <div className="top-bar__right-element">
          <SocialMediaIcons size="22" color="#fff" />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
