import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// import { Accordion, AccordionItem } from "react-accessible-accordion";
import "./PurchaseTracking.scss";
import { FaShoppingCart, FaUser } from "react-icons/fa";
import { db } from "../../firebase/dbAccess";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
const PurchaseTracking = () => {
  const userId = useSelector((state) => state.user.userId);

  const [orderedPurchases, setOrderedPurchases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getSalesDetails = async (db) => {
      const saleCol = collection(db, "Ventas");

      // Filtrar directamente en la consulta para obtener solo los mensajes del usuario actual
      const purchase = query(saleCol, where("id_usuario", "==", userId));

      const purchasesSnapshot = await getDocs(purchase);

      // Mapear los documentos directamente y agregar el ID
      const purchasesList = purchasesSnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      // Mapear los mensajes y agregar el ID
      return purchasesList.map((purchase) => ({
        ...purchase.data,
        id: purchase.id,
      }));
    };
    const fetchData = async () => {
      try {
        const purchasesData = await getSalesDetails(db);

        setIsLoading(false);
        // Ordenar todas las compras por fecha (la más reciente primero)
        const sortedPurchases = purchasesData.sort(
          (a, b) => b.fecha.seconds - a.fecha.seconds
        );

        setOrderedPurchases(sortedPurchases);
      } catch (error) {
        console.log("Error al obtener las compras:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <div className="perfil-header">
      <div className="menu-container">
        <div className="menu">
          <div className="menu-item ">
            <FaUser className="user-icon" />
          </div>
          <Link to="/painel-do-usuario">
            <div className="menu-item ">
              <span>Perfil de usuário</span>
            </div>
          </Link>
          <div className="separator">&#62;</div>
          <div className="menu-item active">
            <span>Compras feitas</span>
          </div>
        </div>
      </div>

      <h1 className="title text-3xl font-bold">Registro de pedido</h1>

      {/* Menú Tab */}
      {orderedPurchases.length > 0 ? (
        <TabContent isLoading={isLoading} orderedPurchases={orderedPurchases} />
      ) : (
        <NoPurchaseMessage />
      )}
    </div>
  );
};

const TabContent = ({ isLoading, orderedPurchases }) => {
  const convertirFecha = (fechaObj, diasAgregar = 0) => {
    const fechaEnMilisegundos = fechaObj.seconds * 1000;
    const fecha = new Date(fechaEnMilisegundos);
    fecha.setDate(fecha.getDate() + diasAgregar); // Agregar días a la fecha
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const anio = fecha.getFullYear().toString();
    return `${dia}/${mes}/${anio}`;
  };
  return (
    <Tabs>
      <TabList>
        {orderedPurchases.some(
          (purchase) => purchase.estado !== "Concluida"
        ) && <Tab>Compras atuais</Tab>}
        {orderedPurchases.some(
          (purchase) => purchase.estado !== "Concluida"
        ) && <Tab>Detalhe da compra</Tab>}
        {orderedPurchases.some(
          (purchase) => purchase.estado === "Concluida"
        ) && <Tab>Histórico</Tab>}
      </TabList>
      {orderedPurchases.some((purchase) => purchase.estado !== "Concluida") && (
        <TabPanel>
          <div className="table-container">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th data-label="ID da compra">ID da compra</th>
                    <th data-label="Data da compra">Data da compra</th>
                    <th data-label="Data de entrega">
                      Data de entrega estimada
                    </th>
                    <th data-label="Frete escolhido">Serviço de frete</th>
                    <th data-label="Custo do frete">Custo do frete</th>
                    <th data-label="Total">Total</th>
                    <th data-label="Status do pedido">Status do pedido</th>
                  </tr>
                </thead>
                <tbody>
                  {orderedPurchases
                    .filter((purchase) => purchase.estado !== "Concluida")
                    .map((purchase) => (
                      <tr key={`${purchase.id}`}>
                        <td data-label="ID da compra">{purchase.id}</td>
                        <td data-label="Data da compra">
                          {convertirFecha(purchase.fecha)}
                        </td>
                        <td data-label="Data de entrega">
                          {purchase.selectedFrete === "PAC"
                            ? convertirFecha(
                                purchase.fecha,
                                purchase.prazoEntregaPACvalor
                              )
                            : convertirFecha(
                                purchase.fecha,
                                purchase.prazoEntregaSedexvalor
                              )}
                        </td>
                        <td data-label="Frete escolhido">
                          {purchase.selectedFrete}
                        </td>
                        <td data-label="Custo do frete">
                          {purchase.freteValue}
                        </td>
                        <td data-label="Total">
                          {Number(purchase.totalPriceWithFrete).toFixed(2)}
                        </td>
                        <td data-label="Status do pedido">{purchase.estado}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </TabPanel>
      )}

      {orderedPurchases.some((purchase) => purchase.estado !== "Concluida") && (
        <TabPanel>
          {/* Contenido para Ventas actuales */}
          <div className="table-container">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th data-label="ID da compra">ID da compra</th>
                    <th data-label="Nome do pedido">Nome do pedido</th>
                    <th data-label="Quantidade">Quantidade</th>
                    <th data-label="Valor por unidade">Valor por unidade</th>
                    <th data-label="Total por produto">Total por produto</th>
                    <th data-label="Status do pedido">Status do pedido</th>
                  </tr>
                </thead>
                <tbody>
                  {orderedPurchases
                    .filter((purchase) => purchase.estado !== "Concluida")
                    .map((purchase) =>
                      purchase.shoppingCart.map((item) => (
                        <tr key={`${purchase.id}-${item.id}`}>
                          <td data-label="ID da compra">{purchase.id}</td>
                          <td data-label="Nome do pedido">
                            {item.product.title}
                          </td>
                          <td data-label="Quantidade">{item.quantity}</td>
                          <td data-label="Valor por unidade">
                            {item.price.toFixed(2)}
                          </td>
                          <td data-label="Total por produto">
                            {(item.price * item.quantity).toFixed(2)}
                          </td>
                          <td data-label="Status do pedido">
                            {purchase.estado}
                          </td>
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            )}
          </div>
          {/* ... */}
        </TabPanel>
      )}
      {orderedPurchases.some((purchase) => purchase.estado === "Concluida") && (
        <TabPanel>
          {/* Contenido para Histórico */}
          <div className="table-container">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th data-label="ID da compra">ID da compra</th>
                    <th data-label="Nome do pedido">Nome do pedido</th>
                    <th data-label="Quantidade">Quantidade</th>
                    <th data-label="Valor por unidade">Valor por unidade</th>
                    <th data-label="Total por produto">Total por produto</th>
                    <th data-label="Data da compra">Data da compra</th>
                    <th data-label="Status do pedido">Status do pedido</th>
                  </tr>
                </thead>
                <tbody>
                  {orderedPurchases
                    .filter((purchase) => purchase.estado === "Concluida")
                    .map((purchase) =>
                      purchase.shoppingCart.map((item) => (
                        <tr key={`${purchase.id}-${item.id}`}>
                          <td data-label="ID da compra">{purchase.id}</td>
                          <td data-label="Nome do pedido">
                            {item.product.title}
                          </td>
                          <td data-label="Quantidade">{item.quantity}</td>
                          <td data-label="Valor por unidade">
                            {item.price.toFixed(2)}
                          </td>
                          <td data-label="Total por produto">
                            {(item.price * item.quantity).toFixed(2)}
                          </td>
                          <td data-label="Data da compra">
                            {convertirFecha(purchase.fecha)}
                          </td>
                          <td data-label="Status do pedido">
                            {purchase.estado}
                          </td>
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            )}
          </div>
          {/* ... */}
        </TabPanel>
      )}
    </Tabs>
  );
};

const NoPurchaseMessage = () => (
  <div className="no-result-container">
    <div className="no-result-icon">
      <FaShoppingCart className="cart-icon" />
    </div>
    <div className="no-result-text">
      <p>Até agora, nenhuma compra foi realizada.</p>
      <p>
        Explore nossa seleção de produtos e aproveite as excelentes
        oportunidades disponíveis.
      </p>
    </div>
  </div>
);

export default PurchaseTracking;
