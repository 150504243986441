import React, { useState } from "react";
import "./sign-in-sign-up.scss";
import SignIn from "../../components/common/sign-in/sign-in";
import SignUp from "../../components/common/sign-up/sign-up";
import FloatingWhatsAppIcon from "../../components/utils/FloatingWhatsAppIcon/FloatingWhatsAppIcon";
import { useDispatch } from "react-redux";
import { clearError } from "../../Store/UserSlice";
import { LogoSVG } from "../../components/utils/LogoSVG";

const SignInSignUp = () => {
  const [isSignInActive, setIsSignInActive] = useState(true);
  const dispatch = useDispatch();
  const togglePanel = () => {
    setIsSignInActive(!isSignInActive);
    dispatch(clearError());
  };

  return (
    <div className="content-background">
      <div className="sign-in-and-sign-up">
        <div className={`SignIn  ${isSignInActive ? "active" : "idle"}`}>
          <SignIn />
        </div>
        <div className={`SignUp  ${isSignInActive ? "idle" : "active"}`}>
          <SignUp />
        </div>

        <div className={`overlay ${isSignInActive ? "left" : "right"}`}>
          <div className="logo-container w-[350px] ">
            <LogoSVG />
          </div>
          <h2 className="title-sign-in-up">
            {isSignInActive
              ? "Eu não tenho uma conta"
              : "Eu já tenho uma conta"}
          </h2>
          <button
            className="inline-flex text-white bg-slate-900 border-0 py-2 px-6 focus:outline-none hover:bg-neutral-400 rounded text-lg uppercase"
            onClick={togglePanel}
          >
            {isSignInActive ? "Cadastre-se" : "Logue-se"}
          </button>
        </div>
      </div>
      <FloatingWhatsAppIcon />
    </div>
  );
};

export default SignInSignUp;
