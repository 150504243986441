import React, { useEffect, useState } from "react";
import Select from "react-select"; // Importa la librería react-select
import "./ShippingForm.scss";
import {
  updateDoc,
  collection,
  // getDocs,
  addDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../firebase/dbAccess";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDataCustomer,
  setDataCustomer,
} from "../../../Store/CustomerDataSlice";
const regionOptions = [
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraima" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" },
];

const checkPostalCode = (name, value, setFormData) => {
  if (name === "postalCode") {
    const numericValue = value.replace(/\D/g, ""); // Remove any non-digit characters
    // Create a formatted postalCode string with a hyphen after the fifth digit
    let formattedPostalCode = numericValue.slice(0, 5);
    if (numericValue.length > 5) {
      formattedPostalCode += "-" + numericValue.slice(5, 8);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedPostalCode,
    }));
  } else {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }
};

const handleChangeData = (formData, dispatch) => {
  dispatch(
    setDataCustomer({
      docId: formData.docId,
      name: formData.name,
      email: formData.email,
      address: formData.address,
      cpf: formData.cpf,
      city: formData.city,
      postalCode: formData.postalCode,
      phone: formData.phone,
      selectedRegion: formData.selectedRegion,
    })
  );
};

const checkEmptyInput = (formData, setFormSubmitted) => {
  // Verificar si hay campos obligatorios vacíos
  const requiredFields = [
    "name",
    "email",
    "cpf",
    "city",
    "address",
    "postalCode",
    "phone",
    "selectedRegion",
  ];
  const errors = {};
  let hasErrors = false;

  requiredFields.forEach((field) => {
    if (!formData[field]) {
      errors[field] = "O campo obrigatório";
      hasErrors = true;
      setFormSubmitted(false);
    }
  });
  // Si hay errores, mostrarlos y no enviar el formulario
  if (hasErrors) {
    alert("Por favor, preencha todos os campos obrigatórios.");
    return hasErrors;
  }
};
const ShippingForm = ({ buttonAtribute, currentUserId, onSubmitForm }) => {
  // Get data form database
  const dataCustomerObject = useSelector(
    (state) => state.customerData.dataCustomerObject
  );
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState(dataCustomerObject); // Inicializar con los datos iniciales

  const dispatch = useDispatch();
  useEffect(() => {
    setFormData(dataCustomerObject);
  }, [dataCustomerObject]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the field is "postalCode" and remove any non-digit characters
    checkPostalCode(name, value, setFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const emptyInput = checkEmptyInput(formData, setFormSubmitted);

    if (emptyInput) {
      return;
    }
    if (formData.postalCode.replace(/\D/g, "").length !== 8) {
      alert("O código postal deve conter exatamente 8 valores numéricos.");
      setFormSubmitted(false);
      return;
    }

    const timestamp = new Date();
    const docId = formData.docId;
    // Comprobar si ya existe un registro para el usuario actual
    if (docId) {
      setFormData((prevFormData) => {
        // Crear una nueva copia del objeto formData
        const updatedFormData = { ...prevFormData };
        // Actualizar el valor o agregar un nuevo valor
        updatedFormData.docId = docId;
        return updatedFormData;
      });
      await updateDoc(doc(db, "DadosPessoaisClt", docId), {
        formData,
        fecha: timestamp,
      });

      handleChangeData(formData, dispatch);
    }
    if (!docId) {
      // Si no existe, crear uno nuevo
      const docRef = await addDoc(collection(db, "DadosPessoaisClt"), {
        id_usuario: currentUserId,
        formData,
        fecha: timestamp,
      });
      // Obtener el ID asignado automáticamente por Firebase
      // const firebaseDocId = docRef.id;
      formData.docId = docRef.id;
      // Actualizar el campo formData.docId dentro del mismo documento
      await updateDoc(docRef, {
        formData: {
          ...formData,
          // docId: firebaseDocId,
        },
      });

      handleChangeData(formData, dispatch);
    }

    // Enviar el formulario
    onSubmitForm(formData);
  };

  const handleDeleteData = async () => {
    if (window.confirm("Tem certeza de que deseja excluir seus dados?")) {
      try {
        if (formData?.docId?.length > 0) {
          const docId = formData.docId;
          await deleteDoc(doc(db, "DadosPessoaisClt", docId));
          dispatch(clearDataCustomer());
        }
      } catch (error) {
        console.error("Error al borrar los datos:", error);
      }
    }
  };
  // Send data to firebase

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <h2 className="title-data-clt text-4xl my-16">Dados pessoais</h2>
      <div className="form-section">
        <label className="form-label">
          Nome:
          <input
            className="form-input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            disabled={formSubmitted} // Disable the field when formSubmitted is true
          />
        </label>
        <label className="form-label">
          Email:
          <input
            className="form-input"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            disabled={formSubmitted} // Disable the field when formSubmitted is true
          />
        </label>
        <label className="form-label">
          Estado:
          <Select
            className="form-select"
            name="selectedRegion"
            options={regionOptions}
            value={formData.selectedRegion}
            onChange={(selectedOption) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                selectedRegion: selectedOption,
              }))
            }
            isDisabled={formSubmitted} // Disable the Select when formSubmitted is true
            // required
          />
        </label>
        <label className="form-label">
          CPF/CNPJ:
          <input
            className="form-input"
            type="text"
            name="cpf"
            value={formData.cpf}
            onChange={handleChange}
            disabled={formSubmitted} // Disable the field when formSubmitted is true
            required
          />
        </label>
        <label className="form-label">
          Cidade:
          <input
            className="form-input"
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            disabled={formSubmitted} // Disable the field when formSubmitted is true
            required
          />
        </label>
        <label className="form-label">
          Endereço:
          <input
            className="form-input"
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
            placeholder="Endereço completo"
            disabled={formSubmitted} // Disable the field when formSubmitted is true
          />
        </label>
        <label className="form-label">
          CEP:
          <input
            className="form-input"
            type="text"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            // pattern="\d*" // Only allow numeric values
            // maxLength="9" // Maximum length, including the hyphen
            required
            disabled={formSubmitted} // Disable the field when formSubmitted is true
          />
        </label>
        <label className="form-label">
          Telefone:
          <input
            className="form-input"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            disabled={formSubmitted} // Disable the field when formSubmitted is true
          />
        </label>
      </div>
      {!formSubmitted && (
        <div className="area-button">
          <button className=" form-button checkout-btn bg-slate-900 hover:bg-neutral-400">
            {buttonAtribute}
          </button>
          {formData?.docId?.length > 0 && (
            <button
              className=" form-button checkout-btn bg-slate-900 hover:bg-neutral-400"
              type="button"
              onClick={handleDeleteData}
            >
              Deletar dados
            </button>
          )}
        </div>
      )}
    </form>
  );
};

export default ShippingForm;
