// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDVgnBZGG1_MT9pU9jc8J8nTjA7G8ZcC2M",
  authDomain: "auth2.tuaregcomex.com",
  databaseURL: "https://tuareq-website-default-rtdb.firebaseio.com",
  projectId: "tuareq-website",
  storageBucket: "tuareq-website.appspot.com",
  messagingSenderId: "775964937233",
  appId: "1:775964937233:web:2d593b777ad71e207575b0",
  measurementId: "G-D01PWQXYXB",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = firebase.auth();

export const firestore = firebase.firestore();
