import React from "react";
import { FcCheckmark } from "react-icons/fc";
import "./CorreiosSection.scss"; // Archivo CSS para estilizar el componente
import correios from "../../../img/correios.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CorreiosSection = () => {
  return (
    <div className="container mx-auto correios-section-container">
      {/* Contenido */}
      <div className="correios-content">
        <h2 className="correios-content-h2">
          Envio Seguro com os Correios do Brasil!
        </h2>
        <p className=" w-full leading-relaxed text-gray-500">
          Faça seu pedido com tranquilidade e receba através dos serviços
          confiáveis dos Correios (PAC/SEDEX).
        </p>

        {/* Ventajas */}
        <div className="advantages-list">
          <div className="advantage-item mb-3 mt-6">
            <span>
              <FcCheckmark />
            </span>
            <h3 className="text-xl font-semibold ">Entrega Garantida</h3>
          </div>
          <p>
            Receba seu produto de forma segura e pontual graças aos Correios do
            Brasil.
          </p>
          <div className="advantage-item mb-3 mt-6">
            <span>
              <FcCheckmark />
            </span>
            <h3 className="text-xl font-semibold ">Cálculo de Frete</h3>
          </div>
          <p>Calcule o custo de envio antes de concluir a sua compra.</p>
        </div>
      </div>
      {/* Imagen de Correios */}
      <div className="correios-image">
        <LazyLoadImage src={correios} alt="Correios Brasil" />
      </div>
    </div>
  );
};

export default CorreiosSection;
