import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <title>
          Tuareg - Bem-vindo à nossa Loja de Rolamentos de Qualidade
        </title>
        <meta
          name="description"
          content="Oferecemos uma ampla variedade de rolamentos de alta qualidade. Nossos produtos são confiáveis e ideais para aplicações industriais e automotivas"
        />
        <meta name="keywords" content="tuareg, rolamentos, qualidade" />
        <meta name="author" content="Tuareg Comercio Exterior" />
      </Helmet>
      <div className="flex items-center justify-center mt-24 mb-24">
        <div className="max-w-2xl  mx-auto px-4">
          <h1 className="text-3xl font-semibold mb-8 text-center">
            Termos e Condições - tuaregcomex.com
          </h1>

          {/* Seção 1: Introdução */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Introdução</h2>
            <p className="text-gray-700 leading-relaxed">
              Bem-vindo ao tuaregcomex.com, operado pela marca Tuareg. Ao
              acessar e utilizar nosso site, você concorda em cumprir com os
              termos e condições estabelecidos abaixo, bem como com todas as
              leis e regulamentos aplicáveis.
            </p>
          </section>

          {/* Seção 2: Uso do Site */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Uso do Site</h2>
            <p className="text-gray-700 leading-relaxed">
              O tuaregcomex.com destina-se exclusivamente para uso pessoal e não
              comercial. O uso indevido, incluindo, mas não se limitando a,
              atividades ilegais, violação de direitos de terceiros ou
              distribuição de conteúdo impróprio, resultará na suspensão
              imediata do acesso.
            </p>
          </section>

          {/* Seção 3: Conta do Usuário */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. Conta do Usuário</h2>
            <p className="text-gray-700 leading-relaxed">
              Ao criar uma conta em tuaregcomex.com, você é responsável por
              manter a confidencialidade de suas informações de login. Qualquer
              atividade realizada em sua conta será de sua responsabilidade, e
              você concorda em nos notificar imediatamente sobre qualquer uso
              não autorizado.
            </p>
          </section>

          {/* Continuaré con más contenido en la siguiente respuesta. */}
          {/* Seção 4: Política de Privacidade */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              4. Política de Privacidade
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Sua privacidade é importante para nós. Todas as informações
              pessoais fornecidas ou coletadas durante a utilização do
              tuaregcomex.com serão tratadas de acordo com nossa Política de
              Privacidade. Recomendamos que você revise nossa política para
              entender como suas informações são protegidas e utilizadas.
            </p>
          </section>

          {/* Seção 5: Compras e Pagamentos */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              5. Compras e Pagamentos
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Ao realizar uma compra em tuaregcomex.com, você concorda em
              fornecer informações precisas de pagamento e autoriza o
              processamento da transação. Reservamo-nos o direito de recusar ou
              cancelar pedidos por qualquer motivo, incluindo limitações de
              estoque, erros de preço ou suspeita de atividade fraudulenta.
            </p>
          </section>

          {/* Seção 6: Devoluções e Reembolsos */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              6. Devoluções e Reembolsos
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Nossa política de devolução e reembolso estabelece os
              procedimentos e condições para devoluções de produtos e
              solicitações de reembolso. Certifique-se de ler e entender nossa
              política antes de fazer uma compra. Reservamo-nos o direito de
              atualizar ou modificar esta política conforme necessário.
            </p>
          </section>

          {/* Seção 7: Limitação de Responsabilidade */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              7. Limitação de Responsabilidade
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Em nenhuma circunstância, incluindo negligência, seremos
              responsáveis por quaisquer danos diretos, indiretos, incidentais,
              especiais ou consequentes resultantes do uso ou incapacidade de
              usar tuaregcomex.com ou dos produtos adquiridos através do site.
            </p>
          </section>

          {/* Seção 8: Alterações nos Termos */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              8. Alterações nos Termos
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Reservamo-nos o direito de revisar, modificar ou atualizar estes
              termos e condições a qualquer momento. As alterações entrarão em
              vigor imediatamente após serem publicadas no site. É sua
              responsabilidade revisar periodicamente esta página para estar
              ciente das atualizações.
            </p>
          </section>

          {/* Continuaré con más contenido en la siguiente respuesta si es necesario. */}
          {/* Seção 9: Direitos de Propriedade */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              9. Direitos de Propriedade
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Todos os conteúdos, incluindo textos, imagens, gráficos, logotipos
              e outros materiais disponíveis em tuaregcomex.com são propriedade
              exclusiva da marca Tuareg ou de seus licenciadores e estão
              protegidos por leis de direitos autorais e outras leis aplicáveis.
            </p>
          </section>

          {/* Seção 10: Rescisão */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">10. Rescisão</h2>
            <p className="text-gray-700 leading-relaxed">
              Reservamo-nos o direito de rescindir ou suspender sua conta e
              acesso a tuaregcomex.com, sem aviso prévio, caso viole qualquer
              termo ou condição estabelecidos aqui. Em caso de rescisão, todas
              as disposições destes termos que por sua natureza devem sobreviver
              à rescisão permanecerão em pleno vigor.
            </p>
          </section>

          {/* Seção 11: Jurisdição e Resolução de Conflitos */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              11. Jurisdição e Resolução de Conflitos
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Estes termos e condições são regidos pelas leis da República
              Federativa do Brasil. Qualquer disputa ou controvérsia decorrente
              ou relacionada ao uso de tuaregcomex.com será submetida à
              jurisdição exclusiva dos tribunais brasileiros.
            </p>
          </section>

          {/* Seção 12: Contato e Suporte */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              12. Contato e Suporte
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Se você tiver dúvidas, comentários ou preocupações sobre estes
              termos e condições, ou se precisar de assistência adicional, entre
              em contato conosco pelo e-mail:{" "}
              <a
                href="mailto:adm@tuaregcomex.com"
                className="text-blue-500 hover:underline"
              >
                adm@tuaregcomex.com
              </a>
              . Estamos aqui para ajudar!
            </p>
          </section>

          {/* Seção 13: Aprovação e Aceitação */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              13. Aprovação e Aceitação
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Ao utilizar tuaregcomex.com, você reconhece e concorda com estes
              termos e condições em sua totalidade. Se você não concorda com
              algum aspecto destes termos, por favor, não continue utilizando
              nosso site.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
