import { createSlice } from "@reduxjs/toolkit";

export const localStorageMiddleware = (store) => (next) => (action) => {
  // Llamamos a la siguiente acción en la cadena
  next(action);

  // Verificamos si la acción es relevante para nuestro caso (por ejemplo, cualquier acción relacionada con el carrito)
  if (action.type.startsWith("cart/")) {
    const state = store.getState().cart;
    const cartKey = `cart_${store.getState().user.userId}`;
    localStorage.setItem(cartKey, JSON.stringify(state));
  }
};

const initialState = {
  shoppingCart: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    loadCart: (state, action) => {
      state.shoppingCart = action.payload.shoppingCart;
    },

    addToCart: (state, action) => {
      const { id, product, quantity } = action.payload;
      const price = parseFloat(product.price);
      // console.log("quantity", quantity);
      const existingItem = state.shoppingCart.find((item) => item.id === id);
      if (existingItem) {
        // Si el producto ya está en el carrito, no hacemos cambios
        return;
      }
      // Si el producto no está en el carrito, agregarlo
      const newItem = {
        id,
        product,
        quantity,
        price,
        totalPrice: price * quantity,
      };
      state.shoppingCart.push(newItem);
    },
    increaseQuantity: (state, action) => {
      const { id: increaseId } = action.payload;
      const item = state.shoppingCart.find((item) => item.id === increaseId);
      if (item.quantity >= item.product.stock) {
        return state; // No se actualiza el estado si la cantidad supera el stock
      }
      item.quantity++;
      item.totalPrice = item.quantity * item.price;
    },
    decreaseQuantity: (state, action) => {
      const { id: increaseId } = action.payload;
      const item = state.shoppingCart.find((item) => item.id === increaseId);

      if (item.quantity === 1) {
        return state; // Si la cantidad es 1, no se actualiza el estado
      }
      item.quantity--;
      item.totalPrice = item.quantity * item.price;
    },
    removeFromCart: (state, action) => {
      const { id: removeId } = action.payload;
      state.shoppingCart = state.shoppingCart.filter(
        (item) => item.id !== removeId
      );
    },
  },
});

export default cartSlice.reducer;
export const {
  loadCart,
  updateLocalStorage,
  addToCart,
  increaseQuantity,
  decreaseQuantity,
  removeFromCart,
} = cartSlice.actions;

// export const getTotalCartQuantity = (state) =>
//   state.cart.shoppingCart.reduce((sum, item) => sum + item.quantity, 0);

export const getTotalCartPice = (state) =>
  state.cart.shoppingCart.reduce((sum, item) => sum + item.totalPrice, 0);
