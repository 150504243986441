export const FormatCurrency = (amount) => {
  if (amount) {
    return amount.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }
};

export const formattedPrice = (totalPrice) =>
  (Math.round(totalPrice * 100) / 100).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
